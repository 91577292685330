//! Add-ons
// Can do phone login when otp for phone is implimented

import { set, useForm } from "react-hook-form";
import { useState } from "react";
import Loading from "../Loading/Loading";
import styles from "./LoginInputForm.module.css";
import logo from "../../images/moolablack.png";
import iphone from "../../images/iphone.png";
import { Link } from "react-router-dom";
import { useContext } from "react";
import FlashNotification from "../FlashNotification/FlashNotification";
import { FlashNotificationContext, LoadingContext } from "../../App";
import { useNavigate } from "react-router-dom";
import LoginWithGoogle from "../WithGoogle/WithGoogle";



const UID = "login";

const LoginInputForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useContext(LoadingContext);
  const [cred, setCred] = useState(false);
  const [flash, setFlash] = useContext(FlashNotificationContext);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const navigate = useNavigate();

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setLogin((prev) => {
  //     return { ...prev, [name]: value };
  //   });

  if (!cred) {
    return (
      <div className={styles.content}>
        {/* content besides the login */}
        <div className={styles.logo}>
            <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
        </div>
        <section className={styles.loginContainer}>
          {/* this will be for the block of login details */}
          <h1 className={styles.loginTitle}>
            Sign in
          </h1>
            <LoginWithGoogle/>            
          <p className={styles.divider}> or </p>
          <p className={styles.text}>Enter your email</p>
          <section className={styles.loginForm}>
            <form
              id="loginForm"
              autoComplete="__s"
              onSubmit={handleSubmit(() => setCred(true))}
            >
              <input
                {...register("email", {
                  required: "Please provide a vaild email",
                  maxLength: {
                    value: 50,
                    message:
                      "Please enter a valid email less than 50 characters.",
                  },
                  pattern: {
                    value: /^[^@]+@[^@]+\.[A-Za-z]{2,}$/,
                    message: "Please provide a valid email",
                  },
                })}
                type="text"
                id={"email" + UID}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              ></input>
              <div className={styles.errors}>
                <span className={styles.error}>{errors.email?.message}</span>
              </div>
            </form>
          </section>
          <div className={styles.nextButtons}>
            <button
              type="submit"
              form="loginForm"
              className={styles.nextButton}
            >
              Continue
            </button>
          </div>
        </section>
        
        <div className={styles.signUpInstead}>
          <span>New to Moohali?</span>{" "}
          <Link className={styles.textForSignUp} to="/register">
            {" "}
            Sign up instead
          </Link>
        </div>
        {/* <p>{ JSON.stringify(email)}</p> */}
      </div>
    );

  } else {
      return (
        <div className={styles.content}>
        {/* content besides the login */}
        <div className={styles.logo}>
            <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
        </div>
        <section className={styles.loginContainer}>
          {/* this will be for the block of login details */}
          <h1 className={styles.loginTitle}>
            Sign in
          </h1>
          
          <span>{email}</span> {" "}
          <span className={styles.textForSignUp} onClick={() => {
            setCred(cred => { setPassword(""); return !cred;})
          }}>Change</span>

          <div className={styles.text + " " + styles.passwordText}> 
            <span>Enter your password</span>
            <span className={styles.textForSignUp} onClick={() => {
              let params = (new URLSearchParams({email})).toString()
              navigate(`/forgot?${params}`)
            }}>Forgot Password?</span>
            </div>
          <section className={styles.loginForm}>
            <form id="passwordForm" onSubmit={handleSubmit(async ()=>  {
            let request = new Request(process.env.REACT_APP_LOGIN_URL, {
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }, 
              body: JSON.stringify({email: email, userPassword: password})}
            );
            try {
              let response = await fetch(request);
              let responseJson = await response.json();
              if (!response.ok) {
                throw new Error(responseJson.error);
              }
              localStorage.setItem("token", responseJson.jwt)
              // setTimeout(() => {
                  navigate("/")
              // }, 2000)
              setFlash({error: false, message: "Logged in successfully", display: true})
                // set flash to sucess 
            } catch (error) {
              setFlash({error: true, message: "Incorrect email or password", display: true})
              // set flash to error 
            }
          })}>
            
            <div className={styles.password}>
            <input
              type="password"
              id={"password" + UID}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              autoComplete="new-password"
            />
            <input
            className={styles.passwordUnit}
            type="checkbox"
            name={"passwordToggle"}
            id={"passwordToggle" + UID}
            onChange={() => {
              let x = document.getElementById("password" + UID);
              if (x.type === "password") {
                x.type = "text";
              } else {
                x.type = "password";
              }
            }}
          ></input>
        </div>
            <div className={styles.errors}>
              <span className={styles.error}>{errors.email?.message}</span>
            </div>
          </form>
          </section>
          <div className={styles.nextButtons}>
            <button
              type="submit"
              form="passwordForm"
              className={styles.nextButton}
            >
              Continue
            </button>
          </div>
        </section>
        
        <div className={styles.signUpInstead}>
          <span>New to Moola?</span>{" "}
          <Link className={styles.textForSignUp} to="/register">
            {" "}
            Sign up instead
          </Link>
        </div>
        {/* <p>{JSON.stringify(login)}</p> */}
        </div>







  
        //       <span>&#8592;</span>
        //       <span>Back</span>
     

      
        //     <div className={styles.signInButton}>
        //       <button type="submit" form="passwordForm" className={styles.nextButton}>
        //         Sign In
        //       </button>
        //     </div>
        //     <div className={styles.signUpInstead}>
        //       <span>New to Moola?</span>{" "}
        //       <Link className={styles.textForSignUp} to="/register">
        //         {" "}
        //         Sign up instead
        //       </Link>
        //     </div>
        //   </section>
        //   {/* <p>{JSON.stringify(login)}</p> */}
        // </div>
      );
    }
};
// localStorage.setItem("token", jsonToken.jwt)

export default LoginInputForm;
