import { useContext, useEffect, useRef, useState } from "react";
import styles from "./otp.module.css"
import { FlashNotificationContext, LoadingContext } from "../../App";
const refreshTime = 10;


const OTP = ({type, email}) => {
    const [flash, setFlash] = useContext(FlashNotificationContext)
    const [loading, setLoading] = useContext(LoadingContext)
    
    const [timer, setTimer] = useState(refreshTime);
    const intervalRef = useRef(null);

    const decTimer = () => {
        setTimer(prev => prev - 1);
        // console.log('hi')
    }
    const refreshTimer = () => {
        setTimer(refreshTime);
    }

    useEffect(() => {
        if (timer <= 0 && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null
        }}, [timer])

    useEffect(() => {
        if (!intervalRef.current) {
            (async () => {  
                setLoading(true);
                let response = await fetch(`${process.env.REACT_APP_GET_OTP}?${(new URLSearchParams({type: type, email: email})).toString()}`, {           //! change the link
                    method: "GET",
                    headers: {
                      'Accept': 'application/json',
                    },
                  })
                  setLoading(false);
                  let json = await response.json();
                  if (!response.ok) {
                    setFlash({error: true, message: json.message, display: true}) 
                    return
                  }
                  intervalRef.current  = (setInterval(decTimer, 1000))
                  setFlash({error: false, message: json.message, display: true})
            })()
        }
        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [])    

    return (
        <div className={styles.otpMessage}>
            {timer > 0 ? <span className={styles.disabled}> Resend in <strong>{(new Date(timer * 1000).toISOString().slice(14,19))}</strong></span> : 
            <span onClick={async () => {
                if (timer <= 0) {
                    setLoading(true);
                    let response = await fetch(`${process.env.REACT_APP_GET_OTP}?${(new URLSearchParams({type: type, email: email})).toString()}`, {           //! change the link
                        method: "GET",
                        headers: {
                            'Accept': 'application/json',
                        },
                        })
                        let json = await response.json();
                        setLoading(false);
                        if (!response.ok) {
                        setFlash({error: true, message: json.message, display: true}) 
                        return
                        }
                        clearInterval(intervalRef.current)
                        refreshTimer();
                        intervalRef.current  = (setInterval(decTimer, 1000))
                        setFlash({error: false, message: json.message, display: true})
                }
            }} className={styles.resendable}>Resend</span>}
        </div>
    )
}

export default OTP;