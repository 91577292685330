import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import FlashNotification from "../FlashNotification/FlashNotification";
// import { useContext } from "react";
// import { FlashNotificationContext } from "../../App";

function useLoggedInNoThreat(setFlash, setAuth) {
    const navigate = useNavigate();
    // const [flash, setFlash] = useContext(FlashNotificationContext);
    useEffect ( () => {
        const checkJWT = async () => {
            try {
                const token = localStorage.getItem("token")
                if (!token) {
                    // setTimeout(
                    //     () => {
                            throw new Error("Please login or sign-up.")
                        // } , 2000
                    // )
                }
                let request = new Request(process.env.REACT_APP_CHECK_JWT_URL, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
                let response = await fetch(request);
                if (!response.ok) {
                    try {
                        let token = localStorage.getItem("token")
                        let request = new Request(process.env.REACT_APP_LOGOUT_URL, {
                            method: "GET",
                            headers: {
                                "authorization": `Bearer ${token}`
                            }
                        })
                        let response = await fetch(request); // This will log the log out. 
                        let json = await response.json();
                        if (!response.ok) {
                            setFlash({error: true, message: json.message, display: true})
                            return
                        }
                        localStorage.clear();
                    } catch (error) {
                        console.log(error.message)
                    }
                    throw new Error("Please re-login.")
                }
                // setFlash(({error: false, message: "you are in", display: true}))
                setAuth(true)
                return; 
            } catch (error) {
                // send the user to log back in if there jwt is not valid, else send them home
                setAuth(false)
            }
        }
        checkJWT(); 
    }, [navigate])
    
}

export default useLoggedInNoThreat;