// TO DO
// 1. conditionall render the sub industries
// 2. add styling and .modules for the divs without classNames


// It doesn't handle the - for business tax id
import React, { useCallback } from "react";
import { useState, useEffect, useContext } from "react";
import styles from "./SignupInputForm.module.css";
import ReCAPTCHA from "react-google-recaptcha";
import { set, useForm } from "react-hook-form";
import ReactDOM from "react-dom/client";
import Loading from "../Loading/Loading.jsx"
import logo from "../../images/moolablack.png"
import { useNavigate, Link } from "react-router-dom";
import { FlashNotificationContext, LoadingContext } from "../../App.js";
import WithGoogle from "../WithGoogle/WithGoogle.jsx";
import OTP from "../OTP/otp.jsx";
const UID = "Signup";

const SignupInputForm = () => {
  // Main sign up form details
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({mode: "onBlur"}); // WE are basically using register to hijack the name attribute and do its magic.


  //! states
  const [cred, setCred] = useState(false);  // this will be toggled by the continue button and the back button in form and otp respecfully
  const [loading, setLoading] = useContext(LoadingContext);
  const [flash, setFlash] = useContext(FlashNotificationContext);
  const navigate = useNavigate();
  const [otp, setOTP] = useState(false);

if (!otp) {
  return (
    <>
      <div className={styles.content}>
        <div className={styles.logo}>
              <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
        </div>
        <section className={styles.loginContainer}>
          {/* this will be for the block of login details */}
          <h1 className={styles.loginTitle}>
            Sign Up
          </h1>
            <WithGoogle/>            
          <p className={styles.divider}> or </p>
          <p className={styles.text}>Enter your first and last name</p>
          <section className={styles.loginForm}>
            <form
              id="signnForm"
              autoComplete="__s"
              onSubmit={handleSubmit(async () => {
                let email = watch("email")
                //! When testing is done, uncomment this
                // if (email.includes("+")){
                //   setFlash({error: true, message: "Invalid Email", display: true})
                //   return
                // }
                let phone = watch("phoneNumber")
                phone = phone.replaceAll(/[^0-9]/g, "")
                if (phone.length !== 10) {
                  setFlash({error: true, message: "Invalid Phone Number", display: true})
                  return
                }
                setOTP(true);
              }
              )
              }
            >
            {/* Name Container */}
            <div className={styles.nameContainer}>
              {" "}
              {/* Delete the module for all when change */}
              <div className={styles.nameFields}>
                <input
                  className={""}
                  type="text"
                  maxLength={49}
                  {...register("firstName", {
                    required: "First name is invalid.",
                    maxLength: {
                      value: 50,
                      message:
                        "First name is invalid.",
                    },
                    pattern: {
                      value: /^[A-Za-z]+$/i,
                      message: "First name is invalid.",
                    },
                  })} // using regular expression to make validation
                  id={"firstName" + UID}
                ></input>
                 <div className={styles.errors}>
                  <span className={styles.error + " " + styles.customMargin}>{errors.firstName?.message}</span>
                </div>
              </div>
              <div className={styles.nameFields}>
              <input
                type="text"
                maxLength={49}
                // className={}
                {...register("lastName", {
                  required: "Last name is invalid.",
                  maxLength: {
                    value: 50,
                    message:
                      "Last name is invalid.",
                  },
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Last name is invalid.",
                  },
                })}
                id={"lastName" + UID}
              ></input>
              <div className={styles.errors}>
              <span className={styles.error}>{errors.lastName?.message}</span>
              </div>
              </div>
            </div>

            <p className={styles.retext}>Enter your email</p>
            {/* Email */}
              <input
                {...register("email", {
                  required: "Please provide a vaild email",
                  maxLength: {
                    value: 50,
                    message:
                      "Please enter a valid email less than 50 characters.",
                  },
                  pattern: {
                    value: /^[^@]+@[^@]+\.[A-Za-z]{2,}$/,
                    message: "Please provide a valid email",
                  },
                })}
                type="text"
                id={"email" + UID}
              ></input>
              <div className={styles.errors}>
                <span className={styles.error}>{errors.email?.message}</span>
              </div>

              {/* Phone Number */}
              <p className={styles.retext}>Enter your phone number</p>
              <div className={styles.phone}>
              <span className={styles.countryCode}>+1</span>
              <input
                className={styles.inputPhone}
                type="text"
                maxLength={12}
                {...register("phoneNumber", {
                  required: "Please enter a valid phone number.",
                  minLength: {
                    value: 10,
                    message: "Please enter a valid phone number.",
                  },
                  // pattern: {
                  //   value: /^\d{3}\-\d{3}\-\d{4}$/,
                  //   message: "Please enter a valid phone number.",
                  // },
                })}
                id={"phoneNumber" + UID}
              ></input>
            </div>
            <div className={styles.errors}>
              <span className={styles.error}>
                {errors.phoneNumber?.message}
              </span>
            </div>

            {/* Password */}
            <p className={styles.retext}>Enter your password</p>
                <div>
                  <div className={styles.password}>
                    <input
                      {...register("password", {
                          required: "Please enter a password.",
                          minLength: {
                            value: 7,
                            message:
                              "Please enter a password longer than 6 characters.",
                          },
                          pattern: {
                            value: /^((?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])).{7,}$/, //.{7,} means we need 7 of any character and the ?= are look aheads to make sure that the characters given have atleast one of the parametes
                            message: "Include an uppercase, a lowercase, and a special character."
                          },
                        })}
                        id={"password" + UID}
                        type="password"
                    ></input>
                    <input
                      className={styles.passwordUnit}
                      type="checkbox"
                      name={"passwordToggle"}
                      id={"passwordToggle" + UID}
                      onChange={() => {
                        let x = document.getElementById("password" + UID);
                        if (x.type === "password") {
                          x.type = "text";
                        } else {
                          x.type = "password";
                        }
                      }}
                    ></input>
                  </div>
                </div>
                <div className={styles.errors}>
                  <span className={styles.error}>{errors.password?.message}</span>
                </div>
              </form>
            </section>
            <div className={styles.nextButtons}>
              <button
                type="submit"
                form="signnForm"
                className={styles.nextButton}
              >
                Continue
              </button>
            </div>
            <p className={styles.terms}> By continuing, you agree to Moolas's <strong>Conditions of Use</strong> and <strong>Privacy Notice</strong>.</p>
        </section>
        <div className={styles.signUpInstead}>
          <span>New to Moola?</span>{" "}
          <Link className={styles.textForSignUp} to="/login">
            {" "}
            Log in instead
          </Link>
        </div>
      </div>
    </>
  );
}


let email = watch("email")
return (
  <div className={styles.content}>
  {/* content besides the login */}
  <div className={styles.logo}>
      <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
  </div>
  <section className={styles.loginContainer}>
    {/* this will be for the block of login details */}
    <h1 className={styles.loginTitle}>
      Verify the code sent to
    </h1>
      <p className={styles.text}>{email}
       <span className={styles.textForSignUp} onClick={() => {
        setOTP(false)
      }}> Change</span> 
      </p>
    <section className={styles.loginForm}>
      <form
        id="otpForm"
        autoComplete="__s"
        onSubmit={handleSubmit(async () => {
          let response = await fetch(process.env.REACT_APP_MAKE_USER, {
            method: "POST",
            headers: {
              "content-type": "application/json"
            },
            body: JSON.stringify({
              firstName: watch("firstName"),
              lastName: watch("lastName"),
              email: watch("email"),
              phoneNumber: watch("phoneNumber"),
              password: watch("password"),
              otp: watch("otp")
            })
          })
          let json = await response.json();
          if (!response.ok) {
            setFlash({error: true, message: json.message, display: true})
            return
          }
            setFlash({error: false, message: json.message, display: true})
            navigate("/login")
        })
      }
      >
        <input
          {...register("otp", {
            required: "Please enter a vaild verification code",
            maxLength: {
              value: 6,
              message:
                "Please enter a valid verification code.",
            },
            minLength: {
              value: 6,
              message:
                "Please enter a valid verification code.",
            },
            pattern: {
              value: /^\d{6}$/,
              message: "Please enter a valid verification code.",
            },
          })}
          type="text"
          id={"email" + UID}
        ></input>
        <div className={styles.errors}>
          <span className={styles.error}>{errors.otp?.message}</span>
        </div>
      </form>
    </section>
    <div className={styles.nextButtons}>
      <button
        type="submit"
        form="otpForm"
        className={styles.nextButton}
      >
        Verify
      </button>
    </div>
    <OTP type="login" email={email}/>
  </section>
  
  <div className={styles.signUpInstead}>
    <span>New to Moola?</span>{" "}
    <Link className={styles.textForSignUp} to="/login">
      {" "}
      Log in instead
    </Link>
  </div>
  {/* <p>{ JSON.stringify(email)}</p> */}
  </div>
)
 
}
  
export default SignupInputForm;



  // hyphenation for phone number change
  // let [isPhoneNumber, setPhoneNumber] = useState(false);
  // const phoneNumberChange = (e) => {
  //   let x = document.getElementById("phoneNumber" + UID);
  //   // country code
  //   if (x.value.length > 0) {
  //     setPhoneNumber(true);
  //   } else {
  //     setPhoneNumber(false);
  //   }
  //   // console.log("countrycode", x.value);

  //   // hyphens
  //   if (e.target.value.length === 3) {
  //     console.log(e.target.value);
  //     if (signUp.phoneNumber[3] !== "-") {
  //       e.target.value += "-";
  //     }
  //   }
  //   if (e.target.value.length === 4) {
  //     let value = e.target.value[3];
  //     if (value >= "0" && value <= "9") {
  //       e.target.value = e.target.value.substring(0, 3) + ("-" + value);
  //     }
  //   }
  //   if (e.target.value.length === 7) {
  //     if (signUp.phoneNumber[7] !== "-") {
  //       e.target.value += "-";
  //     }
  //   }
  //   if (e.target.value.length === 8) {
  //     let value = e.target.value[7];
  //     if (value >= "0" && value <= "9") {
  //       e.target.value = e.target.value.substring(0, 7) + ("-" + value);
  //     }
  //   }
  // };