import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import styles from "./SearchBar.module.css"
import Fuse from 'fuse.js';
import magGlass from '../../images/searchwala.png'
import { useNavigate } from 'react-router-dom';
import { itemContext } from '../../pages/AppLayout';

const SearchBar = () => {
    const [allItems, setAllItems] = useContext(itemContext) 
    const [searchResult, setSearchResults] = useState([])
    const [prevTwoChars, setPrevTwoChars] = useState([])
    const [fuse, setFuse] = useState([])
    const [searchFocus, setSearchFocus] = useState(false)

    const SearchResults = ({results}) => {
        console.log(results);
        const navigate = useNavigate()
        const SearchResult = ({item}) => {
            console.log(item);
            return (
                <div className={styles.searchQuery__div}>
                    <div className={styles.SearchResult__div}
                    onClick={() => {
                        navigate(`/brand/${item.brand_ID}/item/${item.item_id}`)
                        setSearchFocus(false)
                    }}
                    >
                        <span>
                        <img className={styles.searchMagGlass__img} src={magGlass}/>
                        </span>
                        {item.item_name}
                       { console.log(item)}
                    </div>  
                </div>
    
            )
        }
        return (
            <>
                {results.slice(0,15).map(item => <SearchResult item={item}/>)}
            </>
        )
    }

    // Search Function
    // {allItems, setSearchResults}

    // function debounce = (fun, delay) => {
    //     let timeoutId;
    //     return function (...args) {
    //         clearTimeout(timeoutId);
    //         setTimeout(() => fun.apply(this, args), delay)
    //     }
    // }


    useEffect(() => {
            let getAllItems = async () => {
                let response = await fetch(process.env.REACT_APP_GET_ALL);
                let json = await response.json();
                setAllItems(json.message)
                console.log(json.message);
                setFuse(new Fuse(json.message, {keys: ["item_name", "brand_name", "item_description", "color_name"], includeScore: true, threshold: 0.4}))
            }
            getAllItems();
    }, [])

    const {
        register,
        watch,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: "onBlur" });

    const handleSearchChange = async (e) => {
        let query = (e.target.value).toLowerCase()
        if (fuse) {
            console.log(fuse);
            let search = fuse.search(query)
            if (search.length === 0) {
                search = []
                // {item: {id: -1, item_name: "No Items Found"}}
            }
            setSearchResults(search.map(item => item.item));
        }
    }

  return (
    <>
        <span className={searchFocus ? styles.background : ""} onClick={() => setSearchFocus(false)}/>
        <div className={styles.searchAndResponse + " " + (searchFocus ? styles.searchAndResponse__focus: "")}>
            <div className={styles.search + " " + (searchFocus ? styles.search__focus: "")}>
                <form className={styles.search__form} onSubmit={handleSubmit((e) => (e.preventDefault))}>
                {/* keep form for syntatic reasons */}
                    <input 
                        {...register("search")}
                    placeholder='Search Moohali'
                    autoComplete='off'
                    className={styles.search__input + " " + (searchFocus ? searchFocus : "")}
                    type="text"
                    id="search"
                    onChange={handleSearchChange}
                    onFocus={() => setSearchFocus(true)}
                    />
                </form>
                <button className={styles.search__button}>
                    <img src={magGlass} alt="search" className={styles.search__icon}/>
                </button>
            </div>
        <div className={styles.result + " " + (searchFocus ? "" : styles.displayNone)}> 
            <SearchResults results={searchResult}/>
        </div>
        </div>
    </>
    
  )
}

export default SearchBar







// let search = fuseOnDescAndNames.search(query)
// console.log(fuseOnDescAndNames);
// if (search.length !== 0) {
//     setSearchResults(search) //.map(item => item.item.item_name))
//     return 
// }


// if (query.length < 2) {
//     return
// }
// let newTwo = query.substring(0, 2);
// if (newTwo === prevTwoChars) {
//     console.log(prevTwoChars);
//     // just do a fuzzy search
//     const fuseOnDescAndNames = new Fuse(allItems, {keys: ["item_name", "brand_name", "item_description", "color_name"], includeScore: true, threshold: 0.4})
//     let search = fuseOnDescAndNames.search(query)
//     console.log(fuseOnDescAndNames);
//     if (search.length !== 0) {
//         setSearchResults(search) //.map(item => item.item.item_name))
//         return 
//     }
// } else {
//     // hit the api and do another fuzzy search
//     setPrevTwoChars(query.substring(0,2))
//     let response = await fetch(`${process.env.REACT_APP_GET_ALL}?${new URLSearchParams({chars: newTwo})}`);
//     let json = await response.json();
//     setAllItems(json.message)
//     const fuseOnDescAndNames = new Fuse(json.message, {keys: ["item_name", "brand_name", "item_description", "color_name"], includeScore: true, threshold: 0.4})
//     const search = fuseOnDescAndNames.search(query)
//     if (search.length !== 0) {
//         setSearchResults(search) //.map(item => item.item.item_name ))
//         return
//     }
// }
//     setSearchResults([{id: -1, title: "Item Not Found"}])
//     return
// // if (query === "") {
// //     setSearchResults(allItems)
// // }
// // let matches = allItems.filter((item) => { console.log(item); return item.brand_name.toLowerCase().includes(query) || item.item_name.toLowerCase().includes(query) || item.item_description.toLowerCase().includes(query)})
// // if (matches.length === 0) {

// // }
// // setSearchResults(matches.map(item => { return {id: item.id, title: item.item_name}}))