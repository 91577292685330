// Upload Image
import { set, useForm } from "react-hook-form";
import { SelectNewImage } from "../components/PictureUpload/PictureUpload.jsx"


const MyItems = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    const formData = new FormData();
    formData.append("brand_id", 10)
    formData.append('id', 43)
    return (
        <div>
            {/* <UploadImage/> */}
            <SelectNewImage fetchURL={process.env.REACT_APP_SET_BRAND_ITEM_IMAGE} formData={formData} aspect_ratio={1} min_dimension={150} isCircular={false}/>
        </div>
    )
}

        {/* <form id="itemImageForm" onSubmit={handleSubmit((data) => {
                // make a sudo form that I will appeend blob data and then send as my body

                })}>
                <input
                {...register("id")}
                placeholder="id"
                />
                <input
                {...register("brand_id")}
                placeholder="brand_id"
                />
                <button type="submit" form="itemImageForm">
                    Submit
                </button>
            </form> */}

export default MyItems