// import styles from "./NotFound.module.css";
import styles from "./Profile.module.css"
import { useEffect, useRef, useState } from "react";
import { get, useForm } from "react-hook-form";
import loading from "../components/Loading/Loading";
import { AuthContext, FlashNotificationContext, LoadingContext } from "../App";
import { useContext } from "react";
import { useFetchWithAuth } from "../utils/utils";
import { DevTool } from "@hookform/devtools";
import { useNavigate, Link } from "react-router-dom";
import ReactCrop, {
  centerCrop,
  convertToPercentCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import photo from "../images/a.png";
import NotFound from "./NotFound";
import { SelectNewImage } from "../components/PictureUpload/PictureUpload";

const Profile = () => {
  const [flash, setFlash] = useContext(FlashNotificationContext); // for flash notifcations
  const [loading, setLoading] = useContext(LoadingContext); // for loading after api call
  let [isPhoneNumber, setPhoneNumber] = useState(false); // For the phone number area code
  const fetchWithAuth = useFetchWithAuth(); // custom funtion for calling fetch
  const [userID, setUserID] = useState("");
  // const [otp, setOTP] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const [industryOthers, setIndustryOthers] = useState();
  const [subIndustryOthers, setSubIndustryOthers] = useState();
  const [profilePicture, setProfilePicture] =
    useState();
    // <img id={"imageProfile"} alt={"yourimage"} /> // the profile picture that is retrived from the api call
    const navigate = useNavigate();
  const [updateBlob, setUpdateBlob] = useState();
  const [image, setImage] = useState(); // when setting the orgional image
  const [imageUrl, setImageUrl] = useState();
  

  const [auth, setAuth] = useContext(AuthContext);

  const getProfilePicture = async () => {
    try {
      setLoading(true);

      let token = localStorage.getItem("token");
      let response = await fetch(process.env.REACT_APP_UPDATE_PROFILE_PICTURE, {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          accept: "image/*",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch profile picuture");
      }
      let blob = await response.blob();
      const link = URL.createObjectURL(blob);
      return link;
    } catch (error) {
      setFlash({ error: true, message: error.message, display: true });
    } finally {
      setLoading(false);
    }
  };

  // Hook form for each for
  // ! Profile Information hook form
  const {
    setValue, //
    register, // core api that allows us to register inputs into the hook (callback that returns more props that we can use)
    handleSubmit, // helps us retrive values
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      // email: "",
    },
    mode: "onBlur",
  });
  // ! change password hook form
  const {
    register: registerPass,
    handleSubmit: handleSubmitPass,
    formState: { errors: errorsPass },
  } = useForm({ mode: "onBlur" });

  const update = async (url, data) => {
    try {
      data = { ...data, id: userID }; // add the user id to the put request
      // await fetchWithAuth(process.env.REACT_APP_UPDATE_USER_URL, "PUT", data);
      let token = localStorage.getItem("token");
      let request = new Request(url, {
        // change occurs here
        method: "PUT",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // change occurs here
      });
      setLoading(true);
      let responseObject;
      console.log(url);
      let response = await (responseObject = await fetch(request)).json();
      setLoading(false);
      if (!responseObject.ok) {
        throw new Error(response.message);
      }
      setFlash({ error: false, message: response.message, display: true });
    } catch (error) {
      setLoading(false);
      setFlash({ error: true, message: error.message, display: true });
      // check if jwt was the problem, if so, than return to login screen
      try {
        let token = localStorage.getItem("token");
        let request = new Request(process.env.REACT_APP_CHECK_JWT_URL, {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });
        if (!(await fetch(request)).ok) {
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  const [userInfo, setUserInfo] = useState();
  const [userDataHandoff, setUserDataHandoff] = useState(false);
  const [subPage, setSubPage] = useState(1);
  const watchIndustry = watch("industry");
  const watchSubIndustry = watch("subindustry");
  // const watchAll = watch();
  // console.log(watchAll.dob);

  const SideBar = () => {
    return (
      <nav className={styles.nav}>
        <p>Profile Menu</p>
        <ul>
          <li>
            <button className={styles.subNavButton} onClick={() => {setSubPage(1)}}>
                Personal Information
            </button> 
          </li>
          <li>
            <button className={styles.subNavButton} onClick={() => {setSubPage(2)}}>
              Password Change
            </button>
          </li>
          <li>
            <SelectNewImage fetchURL={process.env.REACT_APP_UPDATE_PROFILE_PICTURE} isCircular={true} purpose="Profile Picture Change"/>
          </li>
        </ul>
        {/* <UploadProfileImage /> */}
      </nav>
    )
  }

  //! Information Update
  const updateUser = async (data) => {
    // remove any fields that did not change
    (Object.keys(data)).forEach(point => {
      if (data[point] === "") {
        delete data[point]
      }
      // if (userInfo[point] === data[point]) {
      //   delete data[point]
      // }
      console.log(data);
    });
    if (Object.keys(data).length === 0) {
      setFlash({error: true, message: "Please update your profile before submitting", display: true})
      return
    }
    data = { ...data, id: userID }; // add the user id to the put request
    if (data.industry === "Others") {
        data.industry = data.otherIndustry;
    }
    if (data.subIndustry === "Others") {
        data.subIndustry = data.otherSubindustry;
    }
    
    delete data.otherIndustry
    delete data.otherSubindustry
    await update(process.env.REACT_APP_UPDATE_USER_URL, data); // try catch is handled inside the function
  };

  const getUserData = async () => {
    try {
      let token = localStorage.getItem("token");
      let request = new Request(process.env.REACT_APP_GET_USER_DATA_URL, {
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // Getting the data and setting it
      setLoading(true);
      let response = await fetch(request);
      if (!response.ok) {
        throw new Error("Failed to load user information");
      }
      let data = (await response.json()).message;
      setUserInfo(data);
      setUserID(data.id);
    } catch (error) {
      console.log(error);
      setFlash({ error: true, message: error.message, display: true });
    } finally {
      setLoading(false);
    }
  };

  const setUserData = async () => {
    try {
      let data = userInfo;
      setValue("firstName", data.firstName);
      setValue("lastName", data.lastName);
      setValue("email", data.email);
      setValue("phoneNumber", data.phoneNumber);
      if (data.dob) {
        // data.dob = new Date(data.dob).getTime() //- (new Date()).getTimezoneOffset() * 60000
        // data.dob = new Date(data.dob);
        // data.dob = `${data.dob.getFullYear()}-${data.dob.getMonth() + 1}-${data.dob.getDate()}`;
        setValue("dob", data.dob.toString().substring(0,10));
      }
 
      // let industryData = await getIndustryData();

      let others;
      if (!industry) {
        throw new Error();
      }
      let defaultIndustry = industry.find(
        (
          specfic // use the names, return the id
        ) => specfic.industry === data.industry
      ); // returns the industry object if there is match in default list of industries
      // if (defaultIndustry) {
      //   // not undef
      //   setValue("industry", defaultIndustry.ind_id); // I am working off of number system for value
      // } else {
      //   // find the index of the other tag and make it that
      //   others = industry.find((specfic) => specfic.industry === "Others");
      //   if (others) {
      //     // not undef
      //     setValue("industry", others.ind_id);
      //   } else {
      //     setValue("industry", industry.length); // becuse they are industry is just industry (could also do industry[industry.length - 1].ind_id but in my current config, it will get the same value)
      //   } // This shouldn't happend, but if all fails, I will put the hardcoded value (end of list as of right now)
      //   setValue("otherIndustry", data.industry); // as the industry will be under something else
      // }

      // after this point, the industry should be set
      // I will now do something similar with the subindustry
      //!
      //!
      //!
      //! MIGHT NOT BE A GOOD PRACTICE
      console.log(watchIndustry);
      let industryValue = 0;
      // if (defaultIndustry) {
      //   industryValue = defaultIndustry.ind_id;
      // } else {
      //   industryValue = others.ind_id;
      // }
      console.log(industryValue);
      await getSubIndustryData(industryValue); // get new data based on the new watch
      setUserDataHandoff(true);
    } catch (error) {
      console.log(error);
      setFlash({ error: true, message: error.message, display: true });
    } finally {
      setLoading(false);
    }
  };

  const continueSetUserData = async () => {
    // now do the same for subindustry
    try {
      let data = userInfo;
      if (!subIndustry) {
        throw new Error();
      }
      console.log(subIndustry);
      let defaultSubIndustry = subIndustry.find(
        (specfic) => specfic.sub_industry_name === data.subIndustry
      );
      if (defaultSubIndustry) {
        // meaning a match was found
        setValue("subindustry", defaultSubIndustry.sub_id);
      } else {
        let others = subIndustry.find(
          (specfic) => specfic.sub_industry_name === "Others"
        );
        if (others) {
          setValue("subindustry", others.sub_id);
        } else {
          setValue("subindustry", subIndustry[subIndustry.length - 1].sub_id);
        } // This shouldn't happend, but if all fails, I will put the hardcoded value (end of list as of right now)
        setValue("otherSubindustry", data.subIndustry);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUserDataHandoff(false);
    }
  };

  const getIndustryData = async () => {
    try {
      setLoading(true);
      let response = await fetch(process.env.REACT_APP_INDUSTRY_URL);
      let json = await response.json();
      if (!response.ok) {
        throw new Error(json.message);
      }
      setIndustryOthers(
        json.message.find((specfic) => specfic.industry === "Others")
      );
      setIndustry(json.message);

      return json.message; // this should return industry data
    } catch (error) {
      setFlash({ error: true, message: error.message, display: true });
    } finally {
      setLoading(false);
    }
  };

  const getSubIndustryData = async (industryValue) => {
    if (!industryValue) {
      industryValue = 1;
    }
    try {
      setLoading(true);
      let response = await fetch(
        `${process.env.REACT_APP_SUBINDUSTRY_URL}?industry_id=${
          watchIndustry === "" ? industryValue : watchIndustry // watch industry will be an int
        }`
      );
      let json = await response.json();
      if (!response.ok) {
        throw new Error(json.message);
      }
      // setSubIndustry(json.message);
      // console.log(json.message);
      // console.log(watchIndustry);
      // json.message.length !== 0
      //   ? setSubIndustryLength(
      //       json.message[json.message.length - 1].sub_id.toString()
      //     )
      //   : setSubIndustryLength("");
      // setSubIndustryLength(json.message[(json.message).length - 1].sub_id)
      //! SET THE LENGTH TO THE INDUSTRY OTHERS AND THEN SET UP THE SUB INDUSTRY TO LOAD APPRIPATLY
      if (json.message.length !== 0) {
        setSubIndustryOthers(
          json.message.find((specfic) => specfic.sub_industry_name === "Others")
        );
        setSubIndustry(json.message); // get new data based on the new watch
      }
      return json.message;
    } catch (error) {
      console.log(error);
      setFlash({ error: true, message: error.message, display: true });
    } finally {
      setLoading(false);
    }
  };

  // Components for editing the data
  // const IndustryOptionGenerator = () => {
  //   let options;
  //   console.log(industry);
  //   // if industry is populated, then populate the options
  //   industry ? (options = industry) : (options = []);
  //   return options.map((data) => (
  //     <option value={data.ind_id}> {data.industry} </option>
  //   ));
  // };
  // const SubindustryOptionGenerator = () => {
  //   let options;
  //   console.log(subIndustry);
  //   // if subIndustry is populated, then populate the options
  //   subIndustry ? (options = subIndustry) : (options = []);
  //   return options.map((data) => (
  //     <option value={data.sub_id}> {data.sub_industry_name} </option>
  //   ));
  // };

  // const OtherIndustry = () => {
  //   console.log(watchIndustry);
  //   console.log(industryOthers);
  //   // setValue("subIndustry", )
  //   return parseInt(watchIndustry) === parseInt(industryOthers?.ind_id) ? ( // This means that if the value of our current industry is equal to the value of others, than we will render the others element
  //     <div className={styles.otherInput}>
  //       <input
  //         maxLength={49}
  //         {...register("otherIndustry", {
  //           required: "Please provide an Industry",
  //         })}
  //         placeholder="Industry *"
  //         type="text"
  //         id="otherIndustry"
  //       />
  //       <div className={styles.errors}>
  //         <span className={styles.error}>{errors.otherIndustry?.message}</span>
  //       </div>
  //     </div>
  //   ) : (
  //     ""
  //   );
  // };

  // const OtherSubindustry = () => {
  //   // setValue("subIndustry", 1)
  //   console.log(subIndustryOthers);
  //   console.log(watchSubIndustry);
  //   return parseInt(watchSubIndustry) ===
  //     parseInt(subIndustryOthers?.sub_id) ? (
  //     <div className={styles.otherInput}>
  //       <input
  //         {...register("otherSubindustry", {
  //           require: "Please provide a Sub-Industry",
  //         })}
  //         placeholder="Sub Industry *"
  //         type="text"
  //         id="otherSubindustry"
  //       />
  //       <div className={styles.errors}>
  //         <span className={styles.error}>
  //           {errors.otherSubindustry?.message}
  //         </span>
  //       </div>
  //     </div>
  //   ) : (
  //     ""
  //   );
  // };

  // const OtpPopup = ({ trigger }) => {
  //   if (trigger) {
  //     return <div className={""}>OTP</div>;
  //   }
  // };

  //! use effect for when the app first renders, so we can have the users data already there
  // once the user is authenticated, get the industry data (only one time)
  useEffect(() => {
    if (auth) {
      (async () => {
        await getIndustryData();
      })();
    }
    // if (auth == true) {
    //   getIndustryData().then(
    //   // data => {
    //   // setIndustry(data);
    //   // getSubIndustryData().then(data => {
    //     // setSubIndustry(data)
    //     getUserData()
    //   // }
    // )
  }, [auth]); // get all industries data
  // }, []);

  // After the intial industry load -> get the user's information
  useEffect(() => {
    if (auth && industry) {
      console.log(industry);
      getUserData();
    }
  }, [industry]);

  // Once the user info loads -> call the first part of setting the data
  useEffect(() => {
    if (auth && userInfo) {
      setUserData();
    }
  }, [userInfo]);

  // Once the the first part of setting the data is done -> do the second part
  useEffect(() => {
    if (auth && subIndustry && userDataHandoff) {
      continueSetUserData();
    }
  }, [subIndustry, userDataHandoff]);

  useEffect(() => {
    if (auth && watchIndustry) {
      getSubIndustryData().then(
        // (data) => {
        // setSubIndustry(data);
        console.log(watchIndustry)
        // console.log(data);
        // }
      );
    }
  }, [watchIndustry, industry]);

  // Intaially getting the profile picture

  //! Components
  const PersonalInformation = () => {
    return (
      <div className={styles.center}>
        {/* Title for user information */}
        <p className={styles.header}>Profile Information</p>
        {/* form */}
        <form
          id="profileInformationForm"
          onSubmit={handleSubmit(updateUser)}
          className={styles.updateProfileForm}
        >
          {/* container for first and last name */}
          <div className={styles.nameContainer}>
            <div className={styles.nameFields}>
              <input
                // first name
                {...register("firstName", {
                  required: "Please enter a valid first name.",
                  maxLength: {
                    value: 50,
                    message:
                      "Please enter a valid first name less than 50 characters.",
                  },
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Please enter a valid first name.",
                  },
                })}
                id={"firstName"}
                placeholder="First Name*"
                type="text"
              ></input>
              <div className={styles.errors}>
                <span className={styles.error + " " + styles.customMargin}>
                  {errors.firstName?.message}
                </span>
              </div>
            </div>
            <div className={styles.nameFields}>
              <input
                // last name
                {...register("lastName", {
                  required: "Please enter a valid last name.",
                  maxLength: {
                    value: 50,
                    message:
                      "Please enter a valid last name less than 50 characters.",
                  },
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Please enter a valid last name.",
                  },
                })}
                id={"lastName"}
                type="text"
                placeholder="Last Name*"
              ></input>
              <div className={styles.errors}>
                <span className={styles.error}>{errors.lastName?.message}</span>
              </div>
            </div>
          </div>{" "}
          {/* end of first and last name container */}
          {/* Start of phone number */}
          <div>
            <input
              id={"phoneNumber"}
              placeholder="Phone Number*"
              type="text"
              maxLength={12}
              {...register("phoneNumber", {
                required: "Please provide a valid phone number.",
                maxLength: {
                  value: 50,
                  message:
                    "Please enter a valid phone number less than 50 characters",
                },
                pattern: {
                  value: /^\d{3}-\d{3}-\d{4}$/,
                  message: "Please provide a valid phone number.",
                },
              })}
            />
            <div className={styles.errors}>
              <span>{errors.phoneNumber?.message}</span>
            </div>
          </div>
          {/* start of email */}
          <div>
            <input
              id={"email"}
              placeholder="Email*"
              type="text"
              disabled
              {...register("email", {
                required: "Please provide a valid email.",
                maxLength: {
                  value: 50,
                  message:
                    "Please enter a valid email less than 50 characters.",
                },
                pattern: {
                  value: /^[^@]+@[^@]+\.[A-Za-z]{2,}$/,
                  message: "Please provide a valid email.",
                },
              })}
            />
            {/* <div>
              <span
                onClick={() => {
                  setOTP((prev) => !prev);
                }}
              >
                Edit your email?
              </span>
              <OtpPopup trigger={otp}>2</OtpPopup>
            </div> */}
            <div>
              <input
                onFocus={() => {
                  let x = document.getElementById("dob");
                  x.type = "date";
                }}
                onBlur={() => {
                  let x = document.getElementById("dob");
                  x.type = "text";
                }}
                min="1900-01-01"
                max={`${new Date().getFullYear() - 18}-${(
                  new Date().getMonth() +
                  1 +
                  ""
                ).padStart(2, "0")}-${(new Date().getDate() + "").padStart(
                  2,
                  "0"
                )}`}
                onChange={(e) => {
                  let x = document.getElementById("dob");
                  const minDate = new Date(x.min);
                  const maxDate = new Date(x.max);
                  const selectedDate = new Date(x.value);
                  if (selectedDate < minDate) {
                    setFlash({
                      error: true,
                      message: "Please enter a valid date of birth",
                    });
                  }
                  if (selectedDate < minDate || selectedDate > maxDate) {
                    setFlash({
                      error: true,
                      message: "Please enter a valid date of birth",
                    });
                  }
                }}
                maxLength={10}
                id="dob"
                placeholder="DOB"
                type="date"
                {...register("dob", {})}
              />
              {/* Start of rendering industry */}
            </div>
            {/* <div className={styles.error}>
              <span className={styles.error}>{errors.email?.message}</span>
            </div> */}
            {/* Start of rendering subindustry */}
          </div>
          {/* Others for industry */}
        </form>{" "}
        {/* end of form */}
        {/*  */}
        {/*  */}
        {/* buttons to commit changes and reset the form */}
        <div className={styles.nextButtons}>
          <button
            form="profileInformationForm"
            type="submit"
            className={styles.nextButton}
          >
            Save
          </button>
          <span className={styles.middleText}> or </span>
          <button
            onClick={async () => {
              // call get data and re-populate
              await getUserData();
            }}
            className={styles.nextButton}
          >
            Reset
          </button>
        </div>
        {/* end of buttons */}
      </div>
    );
  };

  //! Password Update
  const updatePassword = async (data) => {
    data = { ...data, id: userID }; // add the user id to the put request
    if (data.newPassword !== data.newPassword2) {
      setFlash({
        error: true,
        message: "New and confirmation passsword do not match",
        display: true,
      });
    } else {
      await update(process.env.REACT_APP_SET_PASSWORD_LOGGED_IN, data); // try catch is handled inside the function
    }
  };

  const ChangePassword = () => {
    return (
      <div className={styles.center}>
        {/* title */}
        <p className={styles.header}> Change Password</p>
        {/* form for changing the password */}

        <div className={styles.left}>
          <form
            id="passwordUpdateForm"
            onSubmit={handleSubmitPass(updatePassword)}
            className={styles.updateProfileForm}
          >
            {/* Current Password Input */}
            <p className={styles.passwordHeaders}>Current Password</p>
            <div className={styles.password}>
              <input
                className={styles.currentPassword}
                type="password"
                {...registerPass("currentPassword", {
                  required: "Please enter your current password",
                  maxLength: {
                    value: 50,
                    message: "Please enter your current password",
                  },
                })}
                id={"currentPassword"}
                // placeholder="Current Password*"
              />
              <input
                className={styles.passwordUnit}
                type="checkbox"
                name={"currentPasswordToggle"}
                id={"currentPasswordToggle"}
                onChange={() => {
                  let x = document.getElementById("newPassword");
                  let y = document.getElementById("newPassword2");
                  let z = document.getElementById("currentPassword");
                  if (z.type === "password") {
                    x.type = "text";
                    y.type = "text";
                    z.type = "text";
                  } else {
                    x.type = "password";
                    y.type = "password";
                    z.type = "password";
                  }
                }}
              />
            </div>
            <p className={styles.passwordHeaders}>New Password</p>
            <div className={styles.password}>
              <input
                className={styles.newPassword}
                type="password"
                {...registerPass("newPassword", {
                  required: "Please enter a valid new password.",
                  minLength: {
                    value: 7,
                    message: "Please enter a password longer than 6 characters.",
                  },
                  pattern: {
                    value: /^((?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])).{7,}$/, //.{7,} means we need 7 of any character and the ?= are look aheads to make sure that the characters given have atleast one of the parametes
                    message:
                      "Enter a password over 6 characters with an uppercase, a lowercase, and a special character.",
                  },
                })}
                id={"newPassword"}
                // placeholder="New Password*"
                // autoComplete="new-password"
              />
              {/* <input
                className={styles.passwordUnit}
                type="checkbox"
                name={"newPasswordToggle"}
                id={"newPasswordToggle"}
                onChange={() => {
                  let x = document.getElementById("newPassword");
                  let y = document.getElementById("newPassword2");
                  if (x.type === "password") {
                    x.type = "text";
                    y.type = "text";
                  } else {
                    x.type = "password";
                    y.type = "password";
                  }
                }}
              ></input> */}
            </div>
            {/* <div className={styles.errors}>
                      <span className={styles.error + " " + styles.customMargin}>
                        {errors.newPassword?.message}
                      </span>
                </div> */}
            <>
            <p className={styles.passwordHeaders}>Retype Current Password</p>
              <input
                className={styles.newPassword}
                type="password"
                {...registerPass("newPassword2", {
                  validate: (value) => {
                  if (watch("newPassword2") !== watch("newPassword")) {
                    return "Confirmation password does not match"
                  }
                    return true
                  }
                })
                }
                id={"newPassword2"}
                // placeholder="Retype New Password*"
                // autoComplete="new-password"
              />
              <div className={styles.errors}>
                <span className={styles.error + " " + styles.customMargin}>
                  {errorsPass.currentPassword?.message ? errorsPass.currentPassword?.message : (errorsPass.newPassword?.message ? errorsPass.newPassword?.message : (errorsPass.newPassword2?.message ? errorsPass.newPassword2?.message : ""))}
                </span>
              </div>
            </>
          </form> 
        </div>
       
        <div className={styles.nextButtons}>
          <button
            form="passwordUpdateForm"
            type="submit"
            className={styles.nextButton}
          >
            Save
          </button>
        </div>
        {/* <p>{JSON.stringify(watchAll)}</p> */}
      </div>
    );
  };


  // !resetting to the new profile picture
  useEffect(() => {
    if (auth) {
      getProfilePicture().then((data) => setProfilePicture(data));
    }
  }, [auth]);

  useEffect(() => {
    if (updateBlob === true) {
      getProfilePicture().then((data) => {
        setImageUrl(undefined);
        setImage(undefined);
        setUpdateBlob(false);
        setProfilePicture(data);
      });
    }
  }, [updateBlob]);

  const UploadProfileImage = () => {
    const inputRef = useRef(); // for the file input
    const imageUploaded = useRef(); // this is a reference to the imageUploaded in the image tag
    const imagePreview = useRef(); // ref to the cropped canvas that is turned into a blob that is then sumbitted
    const ASPECT_RATIO = 1;
    const MIN_DIMENSION = 150;

    const handleFileChange = (event) => {
      try {
        const selectedFile = event.target.files[0];
        const imageLink = URL.createObjectURL(selectedFile);
        if (selectedFile) {
          if (!selectedFile.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
            throw new Error("File uploaded is not an image");
          }
          // if the there is a file selected after this change
          const image = new Image(); // we will then check if the size is valid before anything else
          image.src = imageLink;
          image.addEventListener("load", (e) => {
            const { naturalWidth, naturalHeight } = e.currentTarget; // This will create an image element that we can then use to test if the image is of a valid size
            if (naturalWidth < MIN_DIMENSION && naturalHeight < MIN_DIMENSION) {
              cancelNewPicture(); // if it doesn't check out, send a flash message and reset the input
              throw new Error("Image must be at least 150 x 150 pixels.");
            } else {
              setImageUrl(URL.createObjectURL(selectedFile)); // if it went well, set the image states
              setImage(selectedFile);
            }
          });
        }
      } catch (error) {
        setFlash({
          error: true,
          message: error.message,
          display: true,
        });
      }
    };
    // const uploadFile = useRef(null);
    const openFileUpload = () => {
      // this is so we can click on something other than the input to open it
      document.getElementById("profilePictureUpload").click();
    };

    // helper for upload
    const dataUrlToBlob = (dataUrl) => {
      // this converts the canvas data url into a binary blob
      const byteString = atob(dataUrl.split(",")[1]); // this gives me a string of the base64-encoded part of the image to character of bytes
      const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0]; // this gets the mime type (which can say iamge/png for example from the larger data:image/png;base64)
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab); // this makes the Array that makes the data readable
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i); // ?
      }
      return new Blob([ab], { type: mimeString }); //
    };

    // on click
    const uploadProfileImage = (image, canvas, crop) => {
      // this screate the profile image that is then uploaded
      // image, canvas, crop state
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("No 2D Context");
      }
      const pixelRatio = window.devicePixelRatio; // Pixel ratio of device
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio); // This is setting the width and height of the canvas
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio); // scales the context tools
      ctx.imageSmoothingQuality = "high";
      ctx.save();

      // Since we are going to be cropping the og image, not the image rendered on the web app, we will then have to scale the cropping accordingly
      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      ctx.translate(-cropX, -cropY); // This will move the image so that the part we want goes into our canvas
      ctx.drawImage(
        image,
        // for the source image
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        // for the canvas
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );
      ctx.restore();
    };

    const cancelNewPicture = () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
        setImageUrl(undefined);
        setImage(undefined);
      }
      inputRef.current.value = "";
    };

    const ProfilePictureImageCropper = ({ src }) => {
      const [crop, setCrop] = useState();

      const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        console.log(e.currentTarget);
        const dynamicWidth = (MIN_DIMENSION / width) * 100;
        const cropObject = makeAspectCrop(
          {
            unit: "%", // percent will allow the cropper to resize based on the dynamic size of the image when resizing the window
            width: dynamicWidth, // size of the cropper
          },
          ASPECT_RATIO,
          width,
          height
        );
        const centeredCrop = centerCrop(cropObject, width, height); // pass the width and height of the image, and the crop object so we can center it
        setCrop(centeredCrop);
      };
      // maxWidth=320
      //     maxHeight=320
      return (
        <div className={styles.imageCrop}>
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => {
              // choose the parameter based on the unit I am working on
              setCrop(percentCrop);
            }}
            circularCrop
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
            // minHeight={MIN_DIMENSION}
            // minonChange={(cropChange) => setCrop(cropChange)}
          >
            <img
              ref={imageUploaded}
              src={src}
              alt="Upload"
              onLoad={onImageLoad}
            ></img>
          </ReactCrop>
          <div className="nextButtons">
            <button onClick={cancelNewPicture}>Cancel</button>
            <button
              onClick={async () => {
                try {
                  uploadProfileImage(
                    imageUploaded.current,
                    imagePreview.current,
                    convertToPixelCrop(
                      crop,
                      imageUploaded.current.width,
                      imageUploaded.current.height
                    )
                  );
                  const dataUrl = imagePreview.current.toDataURL();
                  const blob = dataUrlToBlob(dataUrl);
                  console.log(blob, "blob");
                  //Once the data is converted, it will then be sent through formData
                  const formData = new FormData();
                  let token = localStorage.getItem("token");
                  let base64Url = token.split(".")[1]; // This will get the encoded payload
                  let id = atob(base64Url).split(":")[1].split(",")[0]; // this will get the id in the id from the payload to send via formData
                  formData.append("image", blob, `profilepicture${id}.png`);
                  let response = await fetch(
                    process.env.REACT_APP_UPDATE_PROFILE_PICTURE,
                    {
                      method: "PUT",
                      headers: {
                        authorization: `Bearer ${token}`,
                        Accept: "application/json",
                      },
                      body: formData,
                    }
                  );
                  let json = await response.json();
                  console.log(response, "response");

                  if (!response.ok) {
                    throw new Error(json.message);
                  }
                  setFlash({
                    error: false,
                    message: json.message,
                    display: true,
                  });
                  setUpdateBlob(true);
                } catch (error) {
                  setFlash({
                    error: true,
                    message: error.message,
                    display: true,
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Update
            </button>
          </div>
          {crop ? (
            <canvas className={styles.croppedImage} ref={imagePreview} />
          ) : (
            ""
          )}
        </div>
      );
    };

    // const {
    //   setValue: seValueFile, //
    //   register: registerFile, // core api that allows us to register inputs into the hook (callback that returns more props that we can use)
    //   handleSubmit: handleSubmitFile, // helps us retrive values
    //   watch: watchFile,
    // } = useForm (ƒ
    //   {mode: "onBlur"}
    // );

    return (
      <div className={styles.ProfilePictureUnit}>
        {image ? <ProfilePictureImageCropper src={imageUrl} /> : ""}
        <div className={styles.profileBlurb}>
          <div className={styles.changeProfilePhoto} onClick={openFileUpload}>
            <img className={styles.profilePicture} alt="profile" src={profilePicture} />
          </div>
          <div className={styles.profileText}>
            <h1>{userInfo?.firstName + " " + userInfo?.lastName}</h1>
            <h4>{userInfo?.industry}</h4>
          </div>
        </div>
        <input
          className={styles.realImageUpload}
          onChange={handleFileChange}
          type="file"
          accept="images/*"
          id="profilePictureUpload"
          ref={inputRef}
        />        
        {/* getProfilePicture */}
        {/* <img ref={profileElement} id={'imageProfile'} onClick={openFileUpload} src={profilePicture} alt={"yourimage"} /> */}
        {/* <form
            id="profilePictureForm"
            onSubmit={handleSubmitFile(uploadProfileImage)}
            > */}
        {/* </form> */}
        {/* <button form="profilePictureForm" className={styles.submitPhoto}>Upload Photo</button> */}
      </div>
    );
  };


  //! Return of components
  let component;
  switch(subPage) {
    case (1):
      component = <PersonalInformation />
      break;
    case(2):
      component = <ChangePassword />
      break;
    default:
      component = <NotFound/>
  }

  return (
    <div className={styles.parent}>
      <SideBar/>
      <div className={styles.centerComp}>
        {component}
      </div>
    </div>
  );
};

export default Profile;





//   <div>
// <select
// id="industry"
// placeholder="Industry *"
// {...register("industry", {
//   required: "Please provide an Industry",
// })}
// >
// {/* <option disabled key="0" value="0">
//   Industry *
// </option> */}
// <IndustryOptionGenerator />
// </select>
// </div>
// {/* <div className={styles.error}>
// <span className={styles.error}>{errors.email?.message}</span>
// </div> */}

// {/* Start of rendering subindustry */}
// <select
// id="subindustry"
// placeholder="Sub Industry *"
// {...register("subindustry", {
// require: "Please provide a Sub Industry",
// })}
// >
// {/* <option disabled key="0" value="0">
// Sub Industry *
// </option> */}
// <SubindustryOptionGenerator />
// </select>
// </div>
// <OtherIndustry />
// <OtherSubindustry />
// Others for industry
