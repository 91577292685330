import { useState, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

const Payment = () => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

    // set up the form
    // acct_1P49TBRsHl4n8DZ5
    // const PaymentForm = ({ receiverAccountId, ammount }) => {// so I will be calculating the price and then passing it to this componenent
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    return  (
        <div>
            <form>
                <input
                    type="email"
                    placeholder='Email'
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                >
                </input>
                <CardElement/>
                <button
                    type="submit"
                    disabled={!stripe || isLoading}
                >

                </button>
            </form>
        </div>
    )
}

export default Payment;