// this will be used by responses to tell the user what has occured after communicating with the backend

import styles from "./FlashNotification.module.css"

const FlashNotification = ({error, message, display}) => {
    return(
            <h4 className={styles.message + " " + (error ? styles.error : styles.reg) + " " + ((display) ? "" : styles.closed)}>
                {message}
            </h4>
    )
}

export default FlashNotification; 