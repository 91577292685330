import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ClientSecretContext, FlashNotificationContext, LoadingContext } from '../../App';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import styles from './Checkout.module.css'
import { BuyNowContext } from '../../pages/Cart';

//  DISABLE BUTTON 

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useContext(ClientSecretContext)
  const UID = "Checkout"
  const stripe = useStripe(); // loads instance of stripe
  const elements = useElements(); // allows elements to use stripe in communicating customer data
  const [isProcessing, setIsProcessing] = useContext(LoadingContext)
  const [flash, setFlash] = useContext(FlashNotificationContext)
  const [buyNow, setBuyNow] = useContext(BuyNowContext)
    const options = {
      layout: {
        type: 'tabs',
        // defaultCollapsed: false,
        // radios: false,
        // spacedAccordionItems: false
      },
    }
    
    const [address, setAddress] = useState();
    // to get the user's address
    useEffect(() => {
     setTimeout(() => {
       if (elements) {
         const paymentElement = elements.getElement(AddressElement);
         // check if the eleent exsists, which it should
         if (paymentElement) {
             paymentElement.on("change", (e) => {
                console.log(e)
                setAddress(e.value.address);
             })
         }
       }
     }, 0);
    }, [elements])
    console.log(address);
    
    // on submit
  const checkoutCart = async (e) => {
    // Update payment intent 
    if (clientSecret === "") {
      return 
    }
  
    console.log(address)
    await fetch(process.env.REACT_APP_STRIPE_UPDATE_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        clientSecret: clientSecret,
        address: address // pass the user's billing address to the backend
      })
    }); //this updates the payment intent 


    // add any fees and taxes to the total

    setTimeout(async () => {
      setIsProcessing(true)
      const {error} = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/app/reciept` // This just redircts the user if they have to verify via a rediction, this can be avoided, however good to keep
        }
      })
      if (error) { 
        setFlash({error: true, message: error.message, display: true});
      } else {
        setFlash({error: true, message: "Success", display: true});
        console.log("worked");
      }
      setIsProcessing(false)
    }, 3000);
  }
  
  // if stripe or the elements aren't loaded, then return blank

  if (!stripe || !elements) {
    return 
  }

  return (
    <div className={styles.checkout}>  {/* This provides context to the app for stripe paymet elements */}
      <h1 className={styles.paymentDetailsHeader}>Payment Details</h1>

        <PaymentElement className={styles.addressPayment} options={options}/>
        <AddressElement options={{mode: 'shipping', fields: {
          phone: 'always'
        }}}/>

      <p className={styles.note}>Note: Any charges outside the United States will incur a fee</p>
      <div className={styles.otherButtons}>
        <button className={styles.completeButton} onClick={checkoutCart}>
          Complete
        </button>
        <button className={styles.cancelButton} onClick={() => {setBuyNow(false)}}>
          Cancel
        </button>
      </div>
    </div>
  );
}

const Checkout = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  const [clientSecret, setClientSecret] = useContext(ClientSecretContext)
  const [paymentIntent, setPaymentIntent] = useState("");
  const [flash, setFlash] = useContext(FlashNotificationContext)
  const [buyNow, setBuyNow] = useContext(BuyNowContext)
  const apperance = {
    theme: 'flat',
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: '1.5',
      borderRadius: '7px',
      colorText: '#F0F6F5',
      colorPrimary: '#F0F6F5',
      colorBackground: '##F0F6F5',
      accessibleColorOnColorPrimary: '#262626'
    },
    rules: {
      '.Dropdown': {
        backgroundColor: '#000000'
      },
      '.Block': {
        backgroundColor: 'var(--colorBackground)',
        boxShadow: 'none',
        padding: '12px'
      },
      '.Input': {
        padding: '12px',
        color: "#000000",
        backgroundColor: 'var(--colorPrimary)'
      },
      '.Input:disabled, .Input--invalid:disabled': {
        color: 'lightgray'
      },
      '.Tab': {
        padding: '10px 12px 8px 12px',
        border: 'none'
      },
      '.Tab:hover': {
        border: 'none',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)'
      },
      '.Label': {
        fontWeight: '500'
      }
    }
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_STRIPE_PAYMENT_INTENT, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
    .then(async (response) => {
      let data = (await response.json())
      if (!response.ok) {
        setFlash({error: true, message: data.message, display: true})
        setBuyNow(false)
      } else {
        setPaymentIntent(data.message)
        setClientSecret(data.message)
      }
    })
  }, [])

    // if stripe or the elements aren't loaded, then return blank
  if ( clientSecret === "") {
    return;
  } 
  return (
    <Elements stripe={stripePromise} options={{clientSecret : clientSecret, appearance: apperance}}>
      <CheckoutForm/>
    </Elements>
  );
};

export default Checkout;





// {
//   <form
//   id={"checkoutForm" + UID}
//   onSubmit={handleSubmit(async (data) => {
//     await checkoutCart()
//   })}
//   className={styles.paymentForm}
//   >
//   <input
//       // placeholder='Enter your FirstName as its on your credit cart'
//       register={"firstName"}
//       id={"firstName" + UID}
//   />
//   <input

//       register={"lastName"}
//       id={"lastName" + UID}
//   />
//   <input

//       register={"email"}
//       id={"email" + UID}
//   />

// </form>
// <button 
//   type="submit"
//   form={"checkoutForm" + UID}
//   disabled={isProcessing}>
//   Submit
// </button>        
// }

// const {
//   register,
//   handleSubmit,
//   setValue,
//   formState: { errors }
// } = useForm({mode: "onBlur"});


// useEffect(() => {
//   const fetchAndSetUserData = async () => {
//       let response = await fetch(process.env.REACT_APP_GET_USER_DATA_URL, {
//           headers: {
//               authorization: `Bearer ${localStorage.getItem("token")}`
//           }
//       });
//       let data = await response.json()
//       if (!response.ok) {
//           throw new Error(data.message)
//       }
//       data = data.message
//       setValue("firstName", data.firstName)
//       setValue("lastName", data.lastName)
//       setValue("email", data.email)
//   }
//  try {
//    fetchAndSetUserData();
//  } catch (error) {
//   console.log(error);
//  }
// }, [])