import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const CheckoutComplete = () => {
    const navigate = useNavigate()
    return (
        <div>
        <h1>Done</h1>
        <button onClick={() => {navigate("/app/dashboard")}}>
            return
        </button>
        </div>
    )
}

export default CheckoutComplete