import { useContext } from "react";
import loading from "../components/Loading/Loading";
import { FlashNotificationContext } from "../App";
import { LoadingContext } from "../App";
import { useCallback } from "react";

export const useFetchWithAuth = function () {
    const[flash, setFlash] = useContext(FlashNotificationContext);
    const[loading, setLoading] = useContext(LoadingContext);
    const fetchWithAuth = useCallback(async (url, method="GET", data={}) => {
        const token = localStorage.getItem("token");
        try { 
            // set up the request
            const request = new Request(url, {
              method: method ? method : undefined,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
              },
              body: (data = JSON.stringify(data)) !== '{}' ? data : undefined
            })
            // get the data
            setLoading(true); 
            let response = await fetch(request);
            let json = await response.json(); 
            setLoading(false); 
            if (!(response.ok)) {
                throw new Error(json.error);
            }
            setFlash({error: false, message: json.message, display: true})
            return json.message 
        } catch (error) {
            setFlash({error: true, message: error.message, display: true})
        } finally {
            setLoading(false); 
        }
    }, [setFlash, setLoading])
    return fetchWithAuth
}

