import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import styles from "./LoginWithGoogle.module.css"
import googlelogo from "./google.png"
import { FlashNotificationContext } from "../../App";

const Button = () => {
    const [flash, setFlash] = useContext(FlashNotificationContext)
    const navigate = useNavigate()

    const login = useGoogleLogin({
        flow: 'auth-code',
        onSuccess : async (response) => {
            console.log(response)
            let res = await fetch(process.env.REACT_APP_GOOGLE_JWT, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({token: response})
            })
            let json = await res.json();
            if (!res.ok) {
                setFlash({error: true, message: res.message + "Sign in failed. Please try again", display: true})
                return
            }
            localStorage.setItem("token", `${json.jwt}`);
            navigate('/');
            // check if it is good
        },
        onError: () => {
            setFlash({error: true, message: "Sign in failed. Please try again", display: true})
        }
    })

    return (
        <div className={styles.loginButtonUnit}>
        <img className={styles.googlelogo} src={googlelogo}/>
        <button className={styles.button} onClick={login}>
            Continue with Google
        </button>
        </div>
        
    )
}

const WithGoogle = () => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
           <Button/>
        </GoogleOAuthProvider>
    )
}

export default WithGoogle


/* <div style={{ transform: 'scale(1.5)', display: "flex", justifyContent: "center"}}>
    <GoogleLogin 
    // text="continue_with"
    width="250px"
    onSuccess={}
    onError={}
    />
</div> */