// file of components related to picture uploading

import { useRef, useState, useContext, useEffect } from "react";
import { FlashNotificationContext, LoadingContext } from "../../App";
import styles from "./PictureUpload.module.css"
import ReactCrop, {
    centerCrop,
    convertToPercentCrop,
    convertToPixelCrop,
    makeAspectCrop,
  } from "react-image-crop"; 

export const SelectNewImage = ({fetchURL, aspect_ratio = 1, min_dimension = 150, isCircular = false, otherData = {}, isButtonVisible = true, formData = (new FormData()), purpose = "Select or Drop Image"}) => {

    const dropAreaRef = useRef()
    const inputRef = useRef(); // for the file input
    const imageUploaded = useRef(); // for the new image uploaded
    const imagePreview = useRef()
    const [flash, setFlash] = useContext(FlashNotificationContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [imageUrl, setImageUrl] = useState();
    const ASPECT_RATIO = aspect_ratio; // assume only sqaure images are allowed for now, so asspect ratio will be 1
    const MIN_DIMENSION = min_dimension;

  
    // Event listener for drop

    //! This was meant to be a drag and drop feature, will work on later
  /*
    useEffect(() => {
        setTimeout(() => {
          dropAreaRef.current.addEventListener("dragover", (e) => {
            e.preventDefault();
            console.log(e.dataTransfer.files[0])
          })
          dropAreaRef.current.addEventListener("drop", (e) => {
            e.preventDefault();
            console.log(e.dataTransfer.files[0])
            inputRef.current.files = e.dataTransfer.files
          })
          inputRef.current.addEventListener("change", (e) => {
            // e.preventDefault()
            // handleFileChange(e);
          })
        }, 1000);
    }, [])
*/


    //! Function to make sure file selection is handled
    const handleFileChange = (event) => { 
        try {
          const selectedFile = event.target.files[0];
          const imageLink = URL.createObjectURL(selectedFile); // create an image link
          if (selectedFile) {
            if (!selectedFile.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
              throw new Error("File uploaded is not an image");
            }
            // if the there is a file selected after this change
            const image = new Image(); // we will then check if the size is valid before anything else
            image.addEventListener("load", (e) => {
              const { naturalWidth, naturalHeight } = e.currentTarget; // This will create an image element that we can then use to test if the image is of a valid size
              if (naturalWidth < MIN_DIMENSION && naturalHeight < MIN_DIMENSION) {
                cancelNewPicture(); // if it doesn't check out, send a flash message and reset the input
                setFlash({
                    error: true,
                    message: `Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`,
                    display: true,
                  });
              } else {
                setImageUrl(URL.createObjectURL(selectedFile)); // if it went well, set the image states
              }
            });
            image.src = imageLink;
          }
        } catch (error) {
        cancelNewPicture();
          setFlash({
            error: true,
            message: error.message,
            display: true,
          });
        }
      };
    //! Function to do proper clean up if file is no longer wanted
    const cancelNewPicture = () => {
        if (imageUrl) {
            URL.revokeObjectURL(imageUrl);
            setImageUrl(undefined);
        }
        inputRef.current.value = "";
    };
  
    //! Helper function to turn a data url link (in which the image is stored) to a binary blob
    const dataUrlToBlob = (dataUrl) => {
        // this converts the canvas data url into a binary blob
        const byteString = atob(dataUrl.split(",")[1]); // this gives me a string of the base64-encoded part of the image to character of bytes
        const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0]; // this gets the mime type (which can say iamge/png for example from the larger data:image/png;base64)
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab); // this makes the Array that makes the data readable
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i); // ?
        }
        return new Blob([ab], { type: mimeString }); //
      };

    //! Componenet that does the image cropping
    const ImageCropper = ({ src }) => {
        const [crop, setCrop] = useState();
        //! Function to set the crop on the image
        const onImageLoad = (e) => {
          const { width, height } = e.currentTarget;
          console.log(e.currentTarget);
          const dynamicWidth = (MIN_DIMENSION / width) * 100;
          const cropObject = makeAspectCrop(
            {
              unit: "%", // percent will allow the cropper to resize based on the dynamic size of the image when resizing the window
              width: dynamicWidth, // size of the cropper
            },
            ASPECT_RATIO,
            width,
            height
          );
          const centeredCrop = centerCrop(cropObject, width, height); // pass the width and height of the image, and the crop object so we can center it
          setCrop(centeredCrop);
        };
        //! Function to concer the cropped image into blob and send to the backend
        const uploadNewCroppedPicture = async () => {
            try {
              editImageCanvas(
                imageUploaded.current,
                imagePreview.current,
                convertToPixelCrop(
                  crop,
                  imageUploaded.current.width,
                  imageUploaded.current.height
                )
              );
              const dataUrl = imagePreview.current.toDataURL();
              const blob = dataUrlToBlob(dataUrl);
              console.log(blob, "blob");
              //Once the data is converted, it will then be sent through formData
              let token = localStorage.getItem("token");
              let base64Url = token.split(".")[1]; // This will get the encoded payload
              let id = atob(base64Url).split(":")[1].split(",")[0]; // this will get the id in the id from the payload to send via formData
              formData.append("image", blob, `photoBy${id}.png`);
              let response = await fetch(
                fetchURL,
                {
                  method: "PUT",
                  headers: {
                    authorization: `Bearer ${token}`,
                    Accept: "application/json",
                  },
                  body: formData,
                  otherData: otherData
                }
              );
              let json = await response.json();
              console.log(response, "response");
    
              if (!response.ok) {
                throw new Error(json.message);
              }
              setFlash({
                error: false,
                message: json.message,
                display: true,
              });
            //   setUpdateBlob(true);
            } catch (error) {
              setFlash({
                error: true,
                message: error.message,
                display: true,
              });
            } finally {
              cancelNewPicture();
              setLoading(false);
            }
        }
        //! function to make the cropped image cropped and seperate from the original image
        const editImageCanvas = (image, canvas, crop) => {
            // this screate the profile image that is then uploaded
            // image, canvas, crop state
            const ctx = canvas.getContext("2d");
            if (!ctx) {
              throw new Error("No 2D Context");
            }
            const pixelRatio = window.devicePixelRatio; // Pixel ratio of device
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
      
            canvas.width = Math.floor(crop.width * scaleX * pixelRatio); // This is setting the width and height of the canvas
            canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
      
            ctx.scale(pixelRatio, pixelRatio); // scales the context tools
            ctx.imageSmoothingQuality = "high";
            ctx.save();
      
            // Since we are going to be cropping the og image, not the image rendered on the web app, we will then have to scale the cropping accordingly
            const cropX = crop.x * scaleX;
            const cropY = crop.y * scaleY;
      
            ctx.translate(-cropX, -cropY); // This will move the image so that the part we want goes into our canvas
            ctx.drawImage(
              image,
              // for the source image
              0,
              0,
              image.naturalWidth,
              image.naturalHeight,
              // for the canvas
              0,
              0,
              image.naturalWidth,
              image.naturalHeight
            );
            ctx.restore();
        };
        return (
            <>
            <div className={styles.background}>
            </div>
                <div className={styles.croppingDiv}>
                <ReactCrop
                crop={crop}
                onChange={(pixelCrop, percentCrop) => {
                    // choose the parameter based on the unit I am working on
                    setCrop(percentCrop);
                }}
                circularCrop={isCircular}
                keepSelection // so when clicking outside of crop but inside of image, the crop stays uneffected
                aspect={ASPECT_RATIO}
                minWidth={MIN_DIMENSION}
                // minHeight={MIN_DIMENSION}
                // minonChange={(cropChange) => setCrop(cropChange)}
                >
                <div className={styles.imageToBeCroppedContainer}
                >
                    <img
                    className={styles.imageToBeCropped}
                    ref={imageUploaded}
                    src={src}
                    alt="Upload"
                    onLoad={onImageLoad}
                ></img>
                </div>
                
                </ReactCrop>
                <div className={styles.nextButtons}>
                <button className={styles.button + " " + styles.cancelButton} onClick={cancelNewPicture}>Cancel</button>
                <button
                    className={styles.button + " " + styles.update}
                    onClick={uploadNewCroppedPicture}
                >
                    Update
                </button>
                </div>
                {crop ? (
                <canvas className={styles.croppedImage} ref={imagePreview} />
                ) : (
                ""
                )}
            </div>
            </>
        );
      };

    // This makes the canvas for the cropped section of the image 
    return (
      <div>
        <div ref={dropAreaRef} id="dropArea" >
            {imageUrl ? <ImageCropper src={imageUrl} /> : ""}
            <input className={styles.displayNone} type="file" accept="image/*" ref={inputRef} onChange={handleFileChange}/>
            <button
                className={styles.finalProduct + " " + styles.button + " " + (isButtonVisible ? "" : styles.displayNone)}
                onClick={() => {
                     inputRef?.current.click(); 
                     console.log(inputRef)
                }}
            >
                {purpose}
            </button>
        </div>
      </div>
    )
};