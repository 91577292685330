import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import NotFound from '../NotFound';
import styles from "./ItemPage.module.css"
import { FlashNotificationContext } from '../../App';
import { useForm } from 'react-hook-form';

const ItemPage = () => {
    const [image, setImage] = useState();
    const [item, setItem] = useState();
    const [flash, setFlash] = useContext(FlashNotificationContext);
    const navigate = useNavigate();
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    const { id, brand_id} = useParams()
    console.log(id, brand_id)
    
    useEffect(() => {
        if (id && brand_id) {
            (async () => {
                let params = new URLSearchParams({
                    id: id,
                    brand_id: brand_id
                })
                let response = await fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                let blob = await response.blob()
                const image = URL.createObjectURL(blob);
                setImage(image)
                response = await fetch(`${process.env.REACT_APP_GET_AN_ITEM}?${params.toString()}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                let json = await response.json();
                setItem(json.message[0]) // item json
            })()

        }
    }, [id])

    if (!item || !image) {
        return 
    }

  return (
    <div className={styles.page}>
        <div className={""}>
            <img className={styles.itemImage} src={image}/>
        </div>     
        <div className={styles.addToCart}>
            <h1 className={styles.itemName}>{item.item_name}</h1>
            <h5 className={styles.storeLink}>Vist the <a>{item.brand_name}</a> Store</h5>
            <hr className={styles.line}/>
            <h2 className={styles.itemPrice}>{((item.item_price).toLocaleString("en", {
                style: "currency", currency: "usd", maximumFractionDigits: 2}))}
            </h2>
            <div className={styles.extraDetails}>
                <h5>Brand: {item.brand_name}</h5>
                <h5>Color: {item.color_name}</h5>
                <h4>Description: {item.item_description}</h4>
            </div>
            <hr className={styles.line}/>
            {item.item_quantity <= 0 ? 
            <h1 className={styles.outStock}> Currently Unavaible </h1> :
            <div>
                <h1 className={styles.inStock}> In Stock </h1>
                    <form onSubmit={handleSubmit(async (e) => {
                        let q = parseInt(watch("quantity"))
                        if (q > 0) {
                            let response = await fetch(process.env.REACT_APP_QUICK_ADD_CART, {
                                method: "POST",
                                headers: {
                                  "content-type": "application/json",
                                  authorization: `Bearer ${localStorage.getItem("token")}`
                                },
                                body: JSON.stringify({
                                    cart_item_quantity: q,
                                    item_id: id
                                  }
                                )});
                            let json = await response.json();
                            setFlash({error: !response.ok, message: json.message, display: true})
                            if (response.ok) {
                                navigate("/app/cart") 
                            }
                        }
                    })}>
                        <select 
                        className={styles.quantitySelector}
                        {...register("quantity")}
                        id="quantity">
                            {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30].map(amount => {
                                return (
                                    <option key={amount} value={amount}>
                                        {amount}
                                    </option>
                                )
                            })}
                        </select>
                        <button className={styles.AddToCart} type="submit">Add to Cart</button>
                    </form>
            </div>
            
            // Put the cart information in here
            }
        </div>     
        {/* {JSON.stringify(item)}  */}
    </div>
  )
}

export default ItemPage