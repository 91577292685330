import "./App.css";
import React, {useState, createContext, useEffect, Children, Use} from "react";
import Signup from "./pages/Signup.jsx";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
  Link,
  BrowserRouter,
} from "react-router-dom";

import NotFound from "./pages/NotFound.jsx";
import Home from "./pages/Home.jsx"
import Cart from "./pages/Cart.jsx";
import Dashboard from "./pages/Dashboard.jsx";
import FlashNotification from "./components/FlashNotification/FlashNotification.jsx";
import Login from "./pages/Login.jsx";
import Profile from "./pages/Profile.jsx";
import AppLayout from "./pages/AppLayout.jsx"
import Loading from "./components/Loading/Loading.jsx";
import 'react-image-crop/dist/ReactCrop.css'
import CheckoutComplete from "./pages/CheckoutComplete.jsx";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Catalog from "./pages/Catalog.jsx";
import MyItems from "./pages/MyItems.jsx";
import Explore from "./pages/Explore.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import ProtectedLayout from "./pages/ProtectedLayout.jsx";
import ItemPage from "./pages/ItemPage/ItemPage.jsx";
export const FlashNotificationContext = createContext();
export const LoadingContext = createContext();
export const AuthContext = createContext();
export const ClientSecretContext = createContext();


function App() {
    //! Note: use setFlash in this format below 
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    const [flash, setFlash] = useState({error: "", message:"", display: false});
    const [loading, setLoading] = useState(false);
    const [auth, setAuth] = useState(false)
    const [clientSecret, setClientSecret] = useState("");


  useEffect(() => { // to change the flash varaible
    if (flash.display !== false) {
      setTimeout(() => {
        setFlash((prevFlash) => {
          return {...prevFlash, display: false}
        })
      }, 3000)
      }}, [flash])


  return (
    // <Elements stripe={stripePromise} options={clientSecret}>  {/* This provides context to the app for stripe paymet elements */}
    <AuthContext.Provider value={[auth, setAuth]}>
    <FlashNotificationContext.Provider value={[flash, setFlash]}>
    <LoadingContext.Provider value={[loading, setLoading]}>
    <ClientSecretContext.Provider value={[clientSecret, setClientSecret]}>
      {loading ? <Loading/> : ""}
      <FlashNotification error={flash.error} message={flash.message} display={flash.display}/> 
      <Routes>
        <Route path="/" element={<AppLayout/>}>
          <Route path="/" element={<Explore/>} />
          <Route path="brand">
            <Route path=":brand_id/item/:id" element={<ItemPage/>}/>
          </Route>
        </Route>

        <Route path="/register" element={<Signup/>}/>  {/* element={<SignupLayout />} */}
          {" "}
          {/* if this did not have a path="", then it would just apply the layout to its routes, which would start at / in this case */}
          {/* <Route index element={<SignupInputForm />} />{" "} */}
          {/* index means that it is going to take the place of /register */}
          {/* <Route path="verify" element={<SignupVerify/>}/>
          <Route path="success" element={<SignupSuccess/>}/> */}
        {/* </Route> */}
        {/* <Route path="/login" element={<LoginInputForm/>}/> */}
        {/* <Route path="/test" element={<h1>hi</h1>} /> */}
        <Route path="/login" element={<Login/>} />
        <Route path="/forgot" element={<ForgotPassword/>} />
        
        
        {/* I was trying to make the protect layout applicable to all of the pages */}
        <Route path="/app" element={<AppLayout/>}>
          {/* <Route path="catalog" element={<Catalog/>}/> */}
          {/* <Route path="catalog" element={<Catalog/>}/> */}
          <Route path="cart" element={<Cart/>}/>

          <Route path="" element={<ProtectedLayout/>}>
            <Route path="profile" element={<Profile/>}/>
            <Route path="playground" element={<MyItems/>}/>
            <Route path="complete" element={<CheckoutComplete/>}/>
          </Route>

        </Route>
        <Route path="/dashboard" element={<ProtectedLayout/>}>
            <Route path="sell" element={<Dashboard/>}/>
            <Route path="orders" element={<Dashboard/>}/>
        </Route>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
      </ClientSecretContext.Provider>
    </LoadingContext.Provider>
    </FlashNotificationContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
