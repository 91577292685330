import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext, FlashNotificationContext, LoadingContext } from "../App";
import useLoggedIn from "../components/hooks/useLoggedIn";



const ProtectedLayout = () => {
    const [flash, setFlash] = useContext(FlashNotificationContext);
    const [loading, setLoading] = useContext(LoadingContext); // for loading after api call
    const [auth, setAuth] = useContext(AuthContext)

    useLoggedIn(setFlash, setAuth);
    return(
        <>
            <Outlet/>
        </>
    )
}

export default ProtectedLayout;
