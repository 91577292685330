import Navbar from "../components/Navbar/Navbar";
import { AuthContext, FlashNotificationContext } from "../App";
import { useContext, useEffect, createContext, useState} from "react";
import { Outlet } from "react-router-dom";
import styles from "./AppLayout.module.css"
import { LoadingContext } from "../App";
import useLoggedInNoThreat from "../components/hooks/useLoggedInNoThreat";
export const ProfileContext = createContext(null);
export const itemContext = createContext([]);


const AppLayout = () => {
    const [flash, setFlash] = useContext(FlashNotificationContext);
    const [loading, setLoading] = useContext(LoadingContext); // for loading after api call
    const [auth, setAuth] = useContext(AuthContext)
    const [profilePicture, setProfilePicture] = useState();
    const [searchResult, setSearchResults] = useState([])


    const flag = false;
    useLoggedInNoThreat(setFlash, setAuth);
    const getProfilePicture = async () => {
        try {
            flag = true
   
          let token = localStorage.getItem("token")
          let response = await fetch(process.env.REACT_APP_UPDATE_PROFILE_PICTURE, {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
              accept: "image/*",
            }
          })
          if (!response.ok) {
           throw new Error("Failed to fetch profile picuture")
          }
          let blob = await response.blob();
          const link = URL.createObjectURL(blob);
          return link;
        } catch (error) {
         setFlash({error: true, message: error.message, display: true})
        } finally {
         setLoading(false)
        }
       }

    useEffect(() => {
        if (flag === true ) {
            getProfilePicture().then(data => setProfilePicture(data))
        }
    }, [flag])
    
    return (
        <ProfileContext.Provider value={profilePicture}>
        <itemContext.Provider value={[searchResult, setSearchResults]}>
            <div>
                <Navbar /> 
                {/* The navbar will be on the left side, while all of the other content will be on the right */}
                <div className={styles.margin}>
                    <Outlet/>
                </div>

            </div>    
        </itemContext.Provider>
        </ProfileContext.Provider>
    )
}

export default AppLayout;