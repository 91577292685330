import React from "react";
import styles from "./NotFound.module.css"
import { useNavigate } from "react-router-dom";
import logo from "../images/moolablack.png"

const NotFound = () => {
    // const {id} = useParams();
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <h1 > 404 </h1>
                <h5 className={styles.nobold} > page does not exsist</h5>
                <button className={styles.button} onClick={()=> {
                    navigate("/")
                }}> go back?</button>
            </div>
        </div>
    )
}


export default NotFound;