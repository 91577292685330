import { Link } from "react-router-dom"
import styles from "./Navbar.module.css"
import m from "../../images/moolawhite.png"
// import o from "../../images/o.png"
// import l from "../../images/l.png"
// import a from "../../images/a.png"
import { useNavigate } from "react-router-dom"
import { FlashNotificationContext } from "../../App"
import { LoadingContext } from "../../App"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import cartImage from "../../images/cart.png"
import { AuthContext } from "../../App"
import slashedProfilePicture from "../../images/slashedProfilePicture.png"
import SearchBar from "../SearchBar/SearchBar"
import { itemContext } from "../../pages/AppLayout"

const Navbar = () => {
    const navigate = useNavigate()
    const [flash, setFlash] = useContext(FlashNotificationContext)
    const [loading, setLoading] = useContext(LoadingContext)
    const [auth, setAuth] = useContext(AuthContext)
    const [profilePicture, setProfilePicture] = useState("")
    const [dropDown, setDropDown] = useState(false)
    const profileDropDownRef = useRef(null)
    // const [allItems, setAllItems] = useState([])
    // const [searchResult, setSearchResults] = useState([])


    const getProfilePicture = async () => {
        try {
          setLoading(true);
    
          let token = localStorage.getItem("token");
          let response = await fetch(process.env.REACT_APP_UPDATE_PROFILE_PICTURE, {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
              accept: "image/*",
            },
          });
          if (!response.ok) {
            throw new Error("Failed to fetch profile picuture");
          }
          let blob = await response.blob();
          const link = URL.createObjectURL(blob);
          return link;
        } catch (error) {
          setFlash({ error: true, message: error.message, display: true });
        } finally {
          setLoading(false);
        }
      };

    useEffect(() => {
        if (auth) {
            (async () => {
                setProfilePicture(await getProfilePicture());
            })()
        }
    }, [auth])

    useEffect(() => {
        setDropDown(false)
    }, [navigate])


    const dropDownClick = (event) => {
        if (profileDropDownRef.current && !profileDropDownRef.current.contains(event.target)) {
            setDropDown(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', dropDownClick)
        return () => {
          document.removeEventListener('mousedown', dropDownClick)
        } 
    })



    const DropDownMenu = () => {
        if (!auth) {
            return (
                <>
                </>
            )
        }

        let token = localStorage.getItem("token");
        let base64Url = token.split(".")[1]; // This will get the encoded payload
        let firstName = atob(base64Url).split(":")[3].split(",")[0].replaceAll(`\"`, ''); // this will get the id in the id from the payload to send via formData
        let lastName = atob(base64Url).split(":")[4].split(",")[0].replaceAll(`\"`, ''); // this will get the id in the id from the payload to send via formData
        let fullName = `${firstName} ${lastName}`
        return (
            <div ref={profileDropDownRef} className={styles.profileDropDownWrap}>
            <div className={styles.profileDropDown}>
                <div className={styles.profileInfo}>
                    <img className={styles.profilePictureDropDown} src={profilePicture}/>
                    <h1>
                        {fullName}
                    </h1>
                </div>
            </div>
            <hr className={styles.divider}/>
                 <h2 className={styles.textForLinks} onClick={() => {
                    setDropDown(false)
                    navigate("/app/profile")
                 }}>View Profile</h2>
                <h2 className={styles.textForLinks} onClick={async () => {
                    setDropDown(false)
                    navigate("/login")
                    let token = localStorage.getItem("token")
                    if (!token) {
                        return
                    }
                    try {
                        let request = new Request(process.env.REACT_APP_LOGOUT_URL, {
                            method: "GET",
                            headers: {
                                "authorization": `Bearer ${token}`
                            }
                        })
                        let response = await fetch(request); // This will log the log out. 
                        let json = await response.json();
                        if (!response.ok) {
                            setFlash({error: true, message: json.message, display: true})
                            return
                        }
                        localStorage.clear();
                        window.location.reload();
                        // setFlash({error: false, message: "Logged out successfully", display: true})
                    } catch (error) {
                        setFlash({error: true, message: error.message, display: true})
                    }// clear the users local stroage to rid them of the JWT
                }}>Logout</h2>
             </div>  
        )
    }

    const path = window.location.pathname;
    console.log(path);
    return (
        <nav className={styles.nav} 
        // onMouseLeave={() => 
        //     setTimeout(() => {
        //         setDropDown(false)
        //     }, 20)} 
            >
            {/* <div className={styles.header}>
                    <div className={styles.logoDiv}>
                        <Link to="/">
                            <img className={styles.logoImage} src={m} alt="logo"/>
                        </Link>
                    </div> */}
                    {/* <Link to={auth ? "/app/profile" : "/signup"}>
                        <img className={styles.profilePicture} src={auth ? profilePicture : slashedProfilePicture }/>
                    </Link>
                    <div className={styles.headerRedirects}>
                        <Link to="/app/cart">
                            <img className={styles.cart}src={cartImage} alt="" />
                        </Link>
                    </div> */}
            {/* </div> */}
            <ul className={styles.navLinkHub}>
                <div className={styles.leftNav}>
                        <div className={styles.logo}>
                            <Link to="/">
                                <img className={styles.logoImage} src={m} alt="logo"/>
                            </Link>
                        </div>
                        {/* <div>
                            <button className={styles.navText + " " + (path ==="/" ? styles.selected : "")} onClick={() => {navigate("/")}}>Explore</button>
                        </div>
                        <div>
                            <button className={styles.navText + " " + (path ==="/app/catalog" ? styles.selected : "")} onClick={() => {navigate("/app/catalog")}}>Browse All</button> */}
                            {/* nav link te check if works and thene make ocmpoente  */}
                        {/* </div> */}
                </div>
                <div className={styles.searchBar}>
                    <SearchBar/>
                </div>
                <div className={styles.rightNav}>
                     {/* <button className={styles.navText + " " + (path ==="/dashboard/orders" ? styles.selected : "")} onClick={() => {navigate("/dashboard/orders")}}>Orders</button>
                     <button className={styles.navText + " " + (path ==="/dashboard/sell" ? styles.selected : "")} onClick={() => {navigate("/dashboard/sell")}}>Sell</button> */}
                     <div className={styles.profileUnit}>
                        <img className={styles.profilePicture + " " + (dropDown ? styles.active : "")} onClick={() => {auth ? setDropDown(prev => !prev) : navigate("/login")}} src={auth ? profilePicture : slashedProfilePicture }/>
                        {dropDown ? <DropDownMenu/> : ""}
                     </div>   
                    {/* <Link to={auth ? "/app/profile" : "/login"}>
                    </Link> */}
                    <Link to="/app/cart">
                        <img className={styles.cart}src={cartImage} alt="" />
                    </Link>
                    {/* <li className={""}>
                        <button className={styles.navText + " " +( path === "/app/profile" ? styles.selected : "")} onClick={() => {navigate("/app/profile")}} >Profile</button>
                    </li> */}
                </div>
            </ul>
        </nav>
    )
}

export default Navbar;