import { AuthContext, FlashNotificationContext, LoadingContext } from '../App.js';
import {
  act,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import useLoggedIn from '../components/hooks/useLoggedIn.js';
import { set } from 'react-hook-form';
import Home from './Home.jsx';
import styles from './Cart.module.css';
import Navbar from '../components/Navbar/Navbar.jsx';
import Payment from '../components/Payment/Payment.jsx';
import CartItem from '../components/CartItem/CartItem.jsx';
import { useNavigate } from 'react-router-dom';
import Checkout from '../components/Checkout/Checkout.jsx';
import cartpng from '../images/cart.png'

export const CartContext = createContext();
export const RefreshContext = createContext();
export const WentAboveContext = createContext();
export const BuyNowContext = createContext();

export const ACTIONS = {
  CHANGE_QUANTITY: 'itemQuantity',
};
const Cart = () => {
  const [flash, setFlash] = useContext(FlashNotificationContext);
  const [loader, setLoading] = useContext(LoadingContext)
  let navigate = useNavigate()
  const continueButton = useRef();

  // this is to update each quantity (when the user continues the payment)
  const reducer = (cartItems, action) => {
    switch (action.type) {
      case ACTIONS.CHANGE_QUANTITY:
        let update = updateQuantity(
          action.payload.item_id,
          action.payload.quantity
        );
        console.log(cartItems);
        console.log(update);
        return { ...cartItems, ...update }; //! I wasn't descturing the object, I was doing update: update
      default:
        throw new Error();
    }
  };

  let updateQuantity = (item_id, quantity) => {
    return { [item_id]: quantity };
  };

  const [cartItems, dispatchCartItems] = useReducer(reducer);
  const [refresh, setRefresh] = useState(false);
  const [wentAbove, setWentAbove] = useState(true)
  const [buyNow, setBuyNow] = useState(false)
  const [subtotal, setSubtotal] = useState();
  const [extra, setExtra] = useState('\u2009');
  const [auth, setAuth] = useContext(AuthContext)

  // get the token
  // move the component up
  const [cart, setCart] = useState();
  useEffect(() => {
    if (auth) {
      getCartData()
    }
  }, [auth]);

  useEffect(() => {
    if (refresh) {
      getCartData();
      setRefresh(false)
    }
  }, [refresh])

  useEffect(() => {
      if (cart) {
        let amount = (cart.map((cartItem) => cartItem.cart_item_quantity * cartItem.item_price)).reduce((acc, cur) => acc + cur)
        if (buyNow) {
          (async () => {
            let params =  new URLSearchParams({ // everything needs to be in the scope of the params
              subtotal: amount
            })
            let response = await fetch(`${process.env.REACT_APP_TAX_AND_FEES}?${params}`, {
              headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
              }
            })
            let data = await response.json()
            console.log("hi");
            if (response.ok) {
              setExtra(<span>with est. fees and taxes: <b>{data.message}</b></span>) // this will be the extra string
// bold the text 
            } else {
              setExtra(<span>{data.message}</span>) // this will be the extra string
              // add extra 
            }
            setSubtotal(amount.toLocaleString("en", {
              style: "currency",
              currency: "usd",
              maximumFractionDigits: 2
            }))
          }
        )()
      } else {
        setSubtotal(amount.toLocaleString("en", {
          style: "currency",
          currency: "usd",
          maximumFractionDigits: 2
        }))
      }
    }
  }, [cart, buyNow])

  
  let getCartData = async () => {
    try {
      let request = new Request(process.env.REACT_APP_GET_CART, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      let response = await fetch(request)
      let data = await response.json();
      // setCart()
      setCart(data.message);
      if (!response.ok) {
        continueButton.current.disabled = true;
      }
    } catch (error) {
      setFlash(error.message)
    }
  }

  if (!cart) {
    return (
      <div>
       <div className={styles.empty}>
        <h1> Your Cart is Empty! </h1>
        <div className={styles.divImg}>
          <img alt="cart" className={styles.emptyImg} src={cartpng}/>
        </div>
        <button className={styles.catalogRedirect} onClick={() => {
          navigate("/")
        }}>Find something you like</button>
      </div>
      </div>
    )
  }
    
  // ((parseInt(total_cost) + (parseInt(total_cost) * 0.029 + .30))).toLocaleString("en", {style: "currency", currency: "usd",  maximumFractionDigits: 2})
  // add the tax
  return (
    <CartContext.Provider value={[cartItems, dispatchCartItems]}>
      <RefreshContext.Provider value={[refresh, setRefresh]}>
      <WentAboveContext.Provider value={[wentAbove, setWentAbove]}>
        <BuyNowContext.Provider value={[buyNow, setBuyNow]}>
      <div className={styles.cart}>
        <div className={styles.cartItems}>
          <div className={""}>
            {/* <p>{JSON.stringify(cart)}</p> */}
            <h1 className={styles.cartTitle}>Cart</h1>
            {cart?.map
              ? cart.map((cart_item, index) => (
                  <CartItem key={index} data={cart_item} />
                ))
              : ''}
          </div>
          <div className={""}>
            <div className={styles.rightSidePay + " " + (buyNow ? styles.stripe : "")}>
                <p className={styles.subtotal}>{`Subtotal (${cart.length} ${cart.length !== 1 ? "items" : "item"}): `} <b>{`${subtotal}`}</b></p>
                {!buyNow ? <p className={styles.estFeesAndTaxes}> {'\u2009'} </p> : <p className={styles.estFeesAndTaxes + " " + (extra === '\u2009' ? '' : styles.estFeesAndTaxesColor)}>{extra}</p>}
                {/* this will include the buyers local state sales tax rate (using ip address) and */}
                <button 
                  className={styles.proceedButton}
                  disabled={buyNow}
                  onClick={(e) => {
                    setBuyNow(true)
                  }}>
                  Buy Now
                </button>
            </div>
            {buyNow ? <Checkout/> : ""}
          </div>
          </div>
      </div>
      </BuyNowContext.Provider>
      </WentAboveContext.Provider>
      </RefreshContext.Provider>
    </CartContext.Provider>
  );
};

export default Cart;




              /* <button
                className={styles.proceedButton}
                ref={continueButton}
                onClick={async (e) => {
                  continueButton.current.disabled = true;
                  setLoading(true)
                  // then do the api call //! This might be bad to call the api for each item
                  let ids = Object.getOwnPropertyNames(cartItems).map((id) =>
                    parseInt(id)
                  );
                  let quantities = Object.values(cartItems).map((q) => parseInt(q));
                  let checkout = false;
                  for (let i = 0; i < ids.length; i++) {
                    try {
                      let request = new Request(process.env.REACT_APP_SET_CART, {
                        method: 'POST',
                        headers: {
                          authorization: `Bearer ${localStorage.getItem('token')}`,
                          //  'Accept': 'application/json',
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                          item_id: ids[i],
                          cart_item_quantity: quantities[i],
                        }),
                      });
                      console.log(ids[i], quantities[i]);
                      let response = await fetch(request);
                      let json = await response.json();
                      if (!response.ok) {
                        setFlash({
                          error: true,
                          message: json.message,
                          display: true,
                        });
                        checkout = false;
                      }
                    } catch (error) {
                      setFlash({
                        error: true,
                        message: error.message,
                        display: true,
                      });
                      checkout = false;
                    }
                  }
                  if (!checkout) {              
                      continueButton.current.disabled = false;
                      setLoading(false)
                  }            
                  setCartEdited(true)
                  // get items again to make sure the car is not empty 
                  let request = new Request(process.env.REACT_APP_GET_CART, {
                    headers: {
                      authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                  });
                  let data = await fetch(request)
                  if (data.ok) {
                    navigate("/app/buy")
                  } else {
                    setFlash({error: false, message: "No items to purchase", display: true})
                    continueButton.current.disabled = false;
                  }
                  //! navigate(checkoutpage)
                }}
              >
                Proceed to checkout
              </button> */