import { useContext, useEffect, useRef, useState } from "react"
import styles from "./Explore.module.css"
import CatalogSection from "../components/RowOfItems/RowOfItems"
import Catalog from "./Catalog"

import kinley from "../images/promo.png"
// import kinley from "../images/nano.png"
import { itemContext } from "./AppLayout"
import { useNavigate } from "react-router-dom"

const Carousel = ({image, link}) => {
    const navigate = useNavigate()
    return (
        <div className={styles.c} onClick={() => {
            image ? navigate(link) : navigate('/')
        }}>
            <div className={styles.cText}>
                <div>
                    <h1>Everything you love.</h1>
                    {/* <h1>All at your finger tips.</h1> */}
                </div>
            </div>
            <div className={styles.cImageContainer}>
                <img className={styles.cImage}src={image ? image : kinley} alt="product"></img>
            </div>
        </div>
    )
}

const Row = ({industry}) => {
    const [items, setItems] = useState([])
    useEffect(() => {
        // get all that pertain to that industry
        fetch(`${process.env.REACT_APP_ALL_PRODUCTS}?${(new URLSearchParams({industry}))}`)
        .then(async res => {
            let json = (await res.json()).message;
            if (res.ok) {
                setItems(json) //.splice(0,5))            
            }
        })
       
    }, [])

    if (items.length === 0) {
        return
    }

    return (
        <div className={styles.row}>
            <CatalogSection categoryName={industry} category={items}/>
        </div>
    )
}

const Explore = () => {
    // The new random item picked each time
    const [random, setRandom] = useState("")
    // These will be change every 3 seconds or so
    const [image, setImage] = useState("")
    const [link, setLink] = useState("")
    // Items to get from 
    const [allItems, getAllItems] = useContext(itemContext)
    const [change, setChange] = useState(false)


    const interval = useRef(null);

    useEffect(() => {
        if (interval.current !== null) {
            clearInterval(interval.current)
        }
        interval.current = window.setInterval(async () => {
            let randomItem = allItems[Math.floor(Math.random() * allItems.length)]
            if (randomItem) {
                setRandom({item_id: randomItem.item_id, brand_id: randomItem.brand_ID, link: `/brand/${randomItem.brand_ID}/item/${randomItem.item_id}`})
                setChange(true)
            }
        }, 3000)
        return (
            () => {clearInterval(interval.current)}
        )
    }, [allItems])


    useEffect(() => {
        if (change) {
            (async () => {
                let params = new URLSearchParams({
                    id: random.item_id,
                    brand_id: random.brand_id
                })
                let response = await fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                let blob = await response.blob()
                setImage(URL.createObjectURL(blob))
                setLink(random.link)
                setChange(false)
            })()
        }
    }, [change])

    // useEffect(() => {
    //      let int = window.setInterval(async () => {
    //         let randomItem = allItems[Math.floor(Math.random() * allItemsRe.length)]
    //         let params = new URLSearchParams({
    //             id: randomItem.item_id,
    //         })
    //         let response = await fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
    //             headers: {
    //                 authorization: `Bearer ${localStorage.getItem("token")}`
    //             }
    //         })
    //         let blob = await response.blob()
    //         const image = URL.createObjectURL(blob);
    //         setImage(image)
    //         setRandom(randomItem)
    //     })    
    //     return (
    //         clearInterval(int)
    //     )
    //     }, [])

    return (
        <div className={styles.explore}>
            <Carousel image={image} link={link}/>
            <Catalog/>
            <footer className={styles.footer}>
                    Made by Taran Singh Mohali Wala
            </footer>
            {/* <Row industry="Lifestyle"/> */}
        </div>
    )
}

export default Explore