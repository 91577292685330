import { useContext, useEffect, useRef, useState } from "react";
import styles from "./CartItem.module.css"
import { ACTIONS, CartContext, RefreshContext, WentAboveContext } from "../../pages/Cart";
import { FlashNotificationContext } from "../../App";

const CartItem = ({ data }) => {
    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let {
      item_name,
      item_price,
      cart_item_quantity,
      item_description,
      color_name,
      brand_name,
      brand_id,
      item_updated,
      id,
    } = data;
    console.log(data)

    item_updated = new Date(item_updated)

    const [quantity, setQuanity] = useState(parseInt(cart_item_quantity));
    const [edit, setEdit] = useState(false)
    const [focusInput, setFocusInput] = useState(false)
    const quantityOtherRef = useRef();
    const [cartItems, dispatchCartItems] = useContext(CartContext);
    const [flash, setFlash] = useContext(FlashNotificationContext)
    const [moreInformation, setMoreInformation] = useState(false);
    const [image, setImage] = useState();
    const [otherFocus, setOtherFocus] = useState(false);
    const [refresh, setRefresh] = useContext(RefreshContext)
    const [update, setUpdate] = useState(false)
    const [wentAbove, setWentAbove] = useContext(WentAboveContext)
    const [confirmDelete, setConfirmDelete] = useState(false);


    // useEffect(() => {
    //     console.log(wentAbove);
    //     if (!wentAbove) {
    //       setQuanity(cart_item_quantity)
    //     }
    //   }, [wentAbove])

    useEffect(()=>{ // this focues the quantity editor 
        if(focusInput === true) {
            quantityOtherRef.current.focus()
            setFocusInput(false);
        }
    }, [focusInput])

    useEffect(() => { 
        dispatchCartItems({type: ACTIONS.CHANGE_QUANTITY, payload: { item_id: id, quantity: quantity}})
    }, [quantity])

    useEffect(()=>{
        console.log(id);
        dispatchCartItems({type: ACTIONS.CHANGE_QUANTITY, payload: { item_id: id, quantity: quantity}})
    },[])

    useEffect(() => {
        if (update) {
            // send the update request
            (async () => {
                let request = new Request(process.env.REACT_APP_SET_CART, {
                    method: "POST",
                    headers: {
                        authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        item_id: id,
                        cart_item_quantity: parseInt(quantity)
                    })
                })
                let response = await fetch(request);
                let json = await response.json();
                if (!response.ok) {
                    setFlash({error: true, message: json.message, display: true})
                    if (json.updated_amount) {
                        quantityOtherRef.current.value = (json.updated_amount);
                        setQuanity(json.updated_amount)
                    }
                } else {
                    setFlash({error: false, message: json.message, display: true})
                }
                setRefresh(true) // tell the cart page to refresh the data
                setUpdate(false) // set Update to false
            })()}
    }, [update])

      
    useEffect(() => {
        let params = new URLSearchParams({
            id: id,
            brand_id: brand_id
        })
        fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then(response => {
            return response.blob()
        }).then(blob => {
            const image = URL.createObjectURL(blob);
            setImage(image)
        })
        setQuanity(cart_item_quantity)
        console.log(quantity)
    }, [id]) 


    

    return (
    <div className={styles.cartItem}>
        <div className={styles.mainCartUnit}>
            <img className={styles.cartImage} alt="item" src={image}/>
            <div>
                <h1 className={styles.cartItemTitle}>{item_name}</h1>
                <p className={styles.cartItemDesc}>{item_description}</p>
                <div 
                onClick={() => {
                    setMoreInformation(!moreInformation);
                }} 
                className={styles.moreInfo}>
                    More Information
                </div>
                {moreInformation ? 
                <div className={styles.theInfo}>
                    <p>Color: {color_name}</p>
                    <p>Last Updated: {(`${months[item_updated.getMonth()]} ${item_updated.getFullYear()}` )}</p>
                    <p>Visit the <a href="">{brand_name}</a> store</p>
                </div> : "" }
                <div className={styles.quantityUnit}>
                    {quantity < 10 ?
                    <select 
                        className={styles.quantitySelector}
                        id="quantity"
                        value={quantity}
                        onChange={(e)=>{
                            setQuanity(e.target.value)
                            console.log(e.target.value);
                            if (e.target.value === "10") {
                                setFocusInput(true)
                            } else {
                                setUpdate(true);
                            }
                        }}>
                            <option value={0}>
                            {`0`}
                            </option>
                        { [1,2,3,4,5,6,7,8,9].map(amount => {
                            return (
                                <option key={amount} value={amount}>
                                    {amount}
                                </option>
                            )
                        })}
                        <option key={10} value={10}>10+</option>    
                    </select> : 
                    <>
                        <input className={styles.quantitySelector} 
                        defaultValue={quantity} ref={quantityOtherRef} onFocus={() => {setEdit(true)}} onCopy={(e)=>{e.preventDefault()}} onPaste={(e)=>{e.preventDefault()}} onKeyDown={(e)=>{["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}} type="number" id="quantityOther"/>    
                        {edit ? 
                        <button className={styles.editCartButtons + " " + styles.updateCartButton } onClick={async () => {
                            if (isNaN(quantity) || quantity < 0 || quantity === '') {
                                quantityOtherRef.current.value = cart_item_quantity //! left off on the item price not working when setting the seelct to "" , lowk just kind of skip for next time so I can do stripe 
                            }
                            // this sends the quantity and removes the update button
                            setQuanity(quantityOtherRef.current.value);
                            setUpdate(true);
                            setEdit(false);
                        }}>Update
                        </button> : ""
                    }        
                    </>
                    }
                    <button className={styles.editCartButtons + " " + styles.deleteOneCartButton} onClick={()=>{
                        setQuanity(prev => { 
                        if (prev >= 10) {
                            quantityOtherRef.current.value = prev - 1
                        }    
                        return (prev - 1);
                        }); 
                        setUpdate(true)}}>Remove One</button>                    
                    <button className={styles.editCartButtons + " " + styles.deleteCartButton} onClick={() => {setQuanity(0); setUpdate(true)}}>Delete</button>
                </div>
            </div>
        </div>
        <div className={styles.cost}>
            <h3>{item_price.toLocaleString("en", {style: "currency", currency: "USD"})}</h3>      {/* //! I will need to make the dollar sign dynamic with the country the user is from */}
        </div>
      
      </div>
    );
  };


   

export default CartItem