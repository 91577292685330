import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { set, useForm } from "react-hook-form";
import styles from './Catalog.module.css';
import { AuthContext, FlashNotificationContext } from '../App';
import { useNavigate } from 'react-router-dom';
import sortByImage from "../images/sortby.png"
const CategoryChangeContext = createContext()

const CatalogSection = ({ categoryName, category }) => {
  const [carousel, setCarousel] = useState(0);
  const [fiveItems, setFiveItems] = useState([]);
  const [disableRight, setDisableRight] = useState(false);
  const [disableLeft, setDisableLeft] = useState(false);

  useEffect(() => {
    setCarousel(0)
  }, [category])

  // useEffect for Disabling the next buttons
  useEffect(() => {
    if (carousel * 5 + 5 > category.length - 1) {
      setDisableRight(true)
    } else {
      setDisableRight(false)
    }
    if (carousel * 5 <= 0) {
      setDisableLeft(true)
    } else {
      setDisableLeft(false)
    }
  }, [category, carousel])


  useEffect(() => {
    setFiveItems(category.slice((carousel * 5), (carousel * 5) + 5))
  }, [category, carousel])


  return (
    <div className={styles.carousel}>
      <button className={styles.carousel__button + " " + styles.carousel__button_left} disabled={disableLeft} onClick={() => {setCarousel(prev => prev - 1)}}>
        <div className={styles.upsideDownArrow}>⟶</div>
      </button>
      <div className={styles.catalogSection}>
        <h1>{categoryName}</h1>
        <div className={styles.CatalogSectionItems}>
          {fiveItems.map((item, index) => (
            // (index < 5) ?
            <CatalogItem key={index} item={item} /> 
          //   :
          //   ""
          ))}
        </div>
      </div>
      <button className={styles.carousel__button + " " + styles.carousel__button_right} disabled={disableRight} onClick={() => {setCarousel(prev => prev + 1);
      }}>
        <div>⟶</div>
      </button>
    </div>

  );
};

const CatalogItem = ({ item }) => {
  const [itemImage, setItemImage] = useState();
  const [categoryChange, setCategoryChange] = useContext(CategoryChangeContext);
  const [quickAdd, setQuickAdd] = useState(false);
  const [flash, setFlash] = useContext(FlashNotificationContext)
  const navigate = useNavigate();
  const [auth, setAuth] = useContext(AuthContext)

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  
  useEffect(() => {
    const params = new URLSearchParams({
        id: item.id,
        brand_id: item.brand_ID
    })
    // console.log(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`)
    fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
        return response.blob();
    })
    .then(blob => {
        const image = URL.createObjectURL(blob);
        setItemImage(image)
    })}, [item])

    // useEffect(() => {
    //   if (categoryChange) {
    //     const params = new URLSearchParams({
    //       id: item.id,
    //       brand_id: item.brand_ID
    //   })
    //   fetch(`${process.env.REACT_APP_GET_BRAND_ITEM_IMAGE}?${params.toString()}`, {
    //     headers: {
    //       authorization: `Bearer ${localStorage.getItem('token')}`,
    //     }
    //   })
    //   .then(response => {
    //       return response.blob();
    //   })
    //   .then(blob => {
    //       const image = URL.createObjectURL(blob);
    //       setItemImage(image)
    //   })
    //   setCategoryChange(false);
    // }
    // }, [item, categoryChange])

  

  if (!itemImage || item.is_deleted || !item.item_quantity) {
    return
  }



  const watchAmount = watch("amount");
  let link = `/brand/${item.brand_ID}/item/${item.item_id}`

  return (
    <div className={styles.itemComp}>
      <img onClick={()=>{navigate(link)}} alt="item" src={itemImage} className={styles.image}/>
      <h3 onClick={()=>{navigate(link)}} className={styles.itemTitle}>{item.item_name}</h3>
      <p className={styles.itemDesc}>{item.item_description}</p>
      <div className={styles.priceBottom}>
        <span className={styles.cost}>
        {item.item_price.toLocaleString('en', {
          style: 'currency',
          currency: 'USD',
        })}
      </span>
      {!quickAdd ? 
      <button className={styles.quickAddButton} onClick={() => {setQuickAdd(true);}}> Quick Add </button> : 
      <div className={styles.addQuantity}>
        <form id="quickAddForm"
          onSubmit={handleSubmit((data) => {
            let request = new Request(process.env.REACT_APP_QUICK_ADD_CART, {
              method: "POST",
              headers: {
                "content-type": "application/json",
                authorization: `Bearer ${localStorage.getItem("token")}`
              },
              body: JSON.stringify({
                  cart_item_quantity: parseInt(data.amount),
                  item_id: item.id
                }
              )
            })
            if (auth) {
              fetch(request)
              .then(response => response.json())
              .then(json => {
                setFlash({error: false, message: json.message, display: true})
              })
              .catch((error) => {
                setFlash({error: true, message: error.message, display: true})
              })
            }
              setQuickAdd(false);
              setValue("amount", "")
            if (!auth) {
              navigate("/login")
            }
          }) 
          }
        >
          <input
            className={styles.quantityToAdd}
            {...register("amount")}
            onCopy={(e)=>{e.preventDefault()}} onPaste={(e)=>{e.preventDefault()}} 
            onKeyDown={(e)=>{["e", "E", "+", "-", ".", "ArrowDown", "ArrowUp"].includes(e.key) && e.preventDefault()}}
            type="number"
          />
        </form>
        <button className={styles.addButton} disabled={!watchAmount || watchAmount === "" || parseInt(watchAmount) === 0} type="submit" form="quickAddForm">Add</button>
        <button className={styles.cancelButton} onClick={()=>{
          setQuickAdd(false)
          setValue("amount", "")
          }}>Cancel</button>
      </div>

      }{' '}
      </div>
      {/* add the quick add feature with quantity*/}
      {/* {JSON.stringify(item)} */}
    </div>
  );
};

const Catalog = () => {
  const [catalog, setCatalog] = useState();
  const [ordering, setOrdering] = useState('');
  const orderEnums = ['BRAND', 'INDUSTRY'];
  const [auth, setAuth] = useContext(AuthContext)
  const [sortBy, setSortBy] = useState(false);
  const sortByRef = useRef(null)

  const handleSortByClickOutside = (event) => {
    if (sortByRef.current && !sortByRef.current.contains(event.target)) {
      setSortBy(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleSortByClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleSortByClickOutside)
    }
  }, [])


  const [categoryChange, setCategoryChange] = useState(true)
  useEffect(() => {
    // if (auth) {
      setOrdering(orderEnums[0]);
    // }
  }, []);

  useEffect(() => {
    switch (ordering) {
      case orderEnums[0]:
        fetch(process.env.REACT_APP_GET_BRAND_ITEMS_BY_BRAND, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {setCatalog(data.message); setCategoryChange(true)});
        break;
      case orderEnums[1]:
        fetch(process.env.REACT_APP_GET_BRAND_ITEMS_BY_INDUSTRY, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {setCatalog(data.message); setCategoryChange(true)});
        break;
      default:
        break;
    }
  }, [ordering]);

  if (!catalog) {
    return;
  }

  let categoryArray = Object.getOwnPropertyNames(catalog);

  return (
    <CategoryChangeContext.Provider value={[categoryChange, setCategoryChange]}>
    <div className={styles.catalog}>
      <div className={styles.overSortBy}>
        <div  onClick={() => setSortBy(prev => !prev)} className={styles.sortByBlock}>
          <span> Sort By </span>      
          {sortBy ? 
            <div ref={sortByRef} className={styles.sortByOptions}>
              <button
                className={styles.marginButton}
                onClick={() => {
                  setOrdering(orderEnums[0]);
                }}
              >
                Brand
              </button>
              <button
                className={styles.marginButton}
                onClick={() => {
                  setOrdering(orderEnums[1]);
                }}
              >
                Industry
              </button>
            </div>
            : " "}
            <img className={styles.sortBy}src={sortByImage}/>
        </div> 
        
      </div>



      {categoryArray.map((category, index) => (
        //  (index < 3) ?
          <CatalogSection categoryName={category} category={catalog[category]} /> 
          // :
          // ""
      )
      )}
    </div>
    </CategoryChangeContext.Provider>
  );
};

export default Catalog;
