import React, {useEffect} from 'react';
import ReactDOM, {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider, Link, BrowserRouter} from "react-router-dom"


// create a router: 
// const router = createBrowserRouter([
// ])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

//<React.StrictMode> 
//{/* <FlashNotificationContext.Provider value={[flash, setFlash]}> */}
//{/* <LoadingContext.Provider value={[loading, setLoading]}/> */}

//</React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
