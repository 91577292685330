import React from "react";
import styles from "./Loading.module.css"
import logo from "../../images/loading.gif"
import blur from "../../images/loadingBlur.png"

const Loading = () => {
    return (
        <>
        <div className={styles.loading}>
            <img className={styles.image} alt="Loading..."src={logo} />
        </div>
        <div className={styles.background}>
            <img className={styles.blur} alt={""} src={blur}></img>    
        </div>

        </> 
    )
}

export default Loading;