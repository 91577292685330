import { Link, useNavigate } from "react-router-dom";
import NotFound from "./NotFound"
import styles from "./ForgotPassword.module.css"
import logo from "../images/moolablack.png"
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import OTP from "../components/OTP/otp";
import { FlashNotificationContext } from "../App";

const ForgotPassword = () => {
  const [verified, setVerified]= useState(false);
  const [flash, setFlash] = useContext(FlashNotificationContext)
  let navigate = useNavigate()
    const UID = "forgot"
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
      } = useForm({ mode: "onBlur" });

    let url = new URL(window.location.href);
    if (url.searchParams.size === 0) {
        return <NotFound/>
    }

    const email = (new URLSearchParams(url.search)).get("email");
    if (email === null) {
        return <NotFound/>
    }

    if (!verified) { 
       return (
        <div className={styles.content}>
        {/* content besides the login */}
        <div className={styles.logo}>
            <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
        </div>
        <section className={styles.loginContainer}>
          {/* this will be for the block of login details */}
          <h1 className={styles.loginTitle}>
            Verify the code
          </h1>
          <p className={styles.text}>Enter the verification code sent to <strong>{email}</strong></p>
          <section className={styles.loginForm}>
            <form
              id="otpForm"
              autoComplete="__s"
              onSubmit={handleSubmit(async () => {
                let response = await fetch(process.env.REACT_APP_VERIFY_OTP, {
                  method: "POST",
                  headers: {
                    "content-type": "application/json"
                  },
                  body: JSON.stringify({
                    email: email,
                    otp: watch("otp")
                  })
                })
                let json = await response.json();
                if (!response.ok) {
                  setFlash({error: true, message: json.message, display: true})
                  return
                }
                  setFlash({error: false, message: json.message, display: true})
                  setVerified(true)
              })
            }
            >
              <input
                {...register("otp", {
                  required: "Please enter a vaild verification code",
                  maxLength: {
                    value: 6,
                    message:
                      "Please enter a valid verification code.",
                  },
                  minLength: {
                    value: 6,
                    message:
                      "Please enter a valid verification code.",
                  },
                  pattern: {
                    value: /^\d{6}$/,
                    message: "Please enter a valid verification code.",
                  },
                })}
                type="text"
                id={"email" + UID}
              ></input>
              <div className={styles.errors}>
                <span className={styles.error}>{errors.otp?.message}</span>
              </div>
            </form>
          </section>
          <div className={styles.nextButtons}>
            <button
              type="submit"
              form="otpForm"
              className={styles.nextButton}
            >
              Verify
            </button>
          </div>
          <OTP type="login" email={email}/>
        </section>
        
        <div className={styles.signUpInstead}>
          <span>New to Moohali?</span>{" "}
          <Link className={styles.textForSignUp} to="/register">
            {" "}
            Sign up instead
          </Link>
        </div>
        {/* <p>{ JSON.stringify(email)}</p> */}
        </div>
      )
    }

    return (
      <div className={styles.content}>
        {/* content besides the login */}
        <div className={styles.logo}>
            <Link to="/"><img className={styles.image} src={logo} alt="logo"></img></Link>
        </div>
        <section className={styles.loginContainer}>
          {/* this will be for the block of login details */}
          <h1 className={styles.loginTitle}>
            Change Password
          </h1>
          <section className={styles.loginForm}>
            <form
              id="passwordForm"
              autoComplete="__s"
              onSubmit={handleSubmit(async () => {
                // hit api to change password
                let response = await fetch(process.env.REACT_APP_SET_PASSWORD, {
                  method: "POST",
                  headers: {
                    "content-type" : "application/json"
                  },
                  body: JSON.stringify({
                    password: watch("password"),
                    email: email,
                    otp: watch("otp")
                  })
                }) 
                let json = await response.json()
                console.log(json)
                if (!response.ok) {
                  setFlash({error: true, message: json.message, display: true})
                  return
                }
                  setFlash({error: false, message: json.message, display: true})
                  navigate("/login")

              })
            }
            >
              <p className={styles.text}>Enter your new password</p>
              <div>
                <div className={styles.password}>
                  <input
                    {...register("password", {
                        required: "Please enter a password.",
                        minLength: {
                          value: 7,
                          message:
                            "Please enter a password longer than 6 characters.",
                        },
                        pattern: {
                          value: /^((?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])).{7,}$/, //.{7,} means we need 7 of any character and the ?= are look aheads to make sure that the characters given have atleast one of the parametes
                          message: "Include an uppercase, a lowercase, and a special character."
                        },
                      })}
                      id={"password" + UID}
                      type="password"
                  ></input>
                   <input
                    className={styles.passwordUnit}
                    type="checkbox"
                    name={"passwordToggle"}
                    id={"passwordToggle" + UID}
                    onChange={() => {
                      let x = document.getElementById("password" + UID);
                      let y = document.getElementById("repassword" + UID);
                      if (x.type === "password") {
                        x.type = "text";
                        y.type = "text";
                      } else {
                        x.type = "password";
                        y.type = "password";
                      }
                    }}
                  ></input>
                </div>
                
              </div>
              {/* <div className={styles.errors}>
                <span className={styles.error}>{errors.password?.message}</span>
              </div> */}
              <p className={styles.reenter}>Re-enter your new password</p>
              <input
                {...register("repassword", {
                  required: "Please enter a password.",
                    minLength: {
                      value: 7,
                      message:
                        "Please enter a password longer than 6 characters.",
                    },
                    pattern: {
                      value: /^((?=.*\d)(?=.*[\W_])(?=.*[A-Z])(?=.*[a-z])).{7,}$/, //.{7,} means we need 7 of any character and the ?= are look aheads to make sure that the characters given have atleast one of the parametes
                      message: "Include an uppercase, a lowercase, and a special character."
                    },
                    validate: (value) => {
                      if (value !== watch("password")) {
                        return "Confirmation password does not match"
                      }
                    return true
                    }
                  }
                )}
                  type="password"
                  id={"repassword" + UID}
              ></input>
              <div className={styles.errors}>
                <span className={styles.error}>{errors?.password ? errors.password?.message : (errors?.repassword ? errors.repassword?.message: "")}</span>
              </div>
            </form>
          </section>
          <div className={styles.nextButtons}>
            <button
              type="submit"
              form="passwordForm"
              className={styles.nextButton}
              onClick={async () => {
                try {
                  // error easter egg
                  if (Object.getOwnPropertyNames(errors).length !== 0) {
                    console.log(errors)
                    let response = await fetch(process.env.REACT_APP_PASSWORD_WARNING)
                    if (response.ok) {
                      const wavBlob = await response.blob();
                      const wavURL = URL.createObjectURL(wavBlob)
                      const audio = new Audio(wavURL);
                      await audio.play()
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Set 
            </button>
          </div>
        </section>
        </div>
      )
   


}

export default ForgotPassword



{/* <input
className={styles.passwordUnit}
type="checkbox"
name={"passwordToggle"}
id={"passwordToggle" + UID}
onChange={() => {
  let x = document.getElementById("password" + UID);
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
}}
></input> */}